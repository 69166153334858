<template>
  <div class="fixed inset-0 bg-gray-900 overflow-auto bg-opacity-50 flex items-center justify-center">
    <div class="my-4 mx-4 max-h-screen overflow-y-auto 
    bg-white p-8 max-w-3xl w-full mx-auto rounded-lg shadow-lg">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Lighter track */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #939191; /* Darker thumb */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker hover state */
}
</style>
