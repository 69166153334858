<template>
  <LayoutComp>
    <div class="p-10 max-w-lg mx-auto mb-34 bg-white rounded-lg shadow-lg">
      <header class="text-center">
        <h2 class="text-3xl font-bold mb-4">Register</h2>
        <p class="mb-6">Complete the registration</p>
      </header>

      <!-- Spinner Component -->
      <SpinnerComp :loading="loading" />

      <form @submit.prevent="registerUser" @keydown.enter.prevent>

        <!-- Common fields -->
        <div class="mb-6">
          <label for="email" class="block text-gray-700 font-bold mb-2">Email</label>
          <input
            type="email"
            id="email"
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
            v-model="form.email"
            readonly
          />
        </div>

        <div class="mb-6">
          <label for="first_name" class="block text-gray-700 font-bold mb-2">First Name</label>
          <input
            type="text"
            id="first_name"
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
            v-model="form.first_name"
          />
          <p v-if="errors.first_name" class="text-red-500 text-sm mt-1">{{ errors.first_name.join(' ') }}</p>
        </div>

        <div class="mb-6">
          <label for="last_name" class="block text-gray-700 font-bold mb-2">Last Name</label>
          <input
            type="text"
            id="last_name"
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
            v-model="form.last_name"
          />
          <p v-if="errors.last_name" class="text-red-500 text-sm mt-1">{{ errors.last_name.join(' ') }}</p>
        </div>

        <div class="mb-6">
          <label for="phone_number" class="block text-gray-700 font-bold mb-2">Phone Number</label>
          <input
            type="text"
            id="phone_number"
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
            v-model="form.phone_number"
          />
          <p v-if="errors.phone_number" class="text-red-500 text-sm mt-1">{{ errors.phone_number.join(' ') }}</p>
        </div>

        <div v-if="$route.query.position === 'teacher'">
          <!-- Teacher-specific fields -->
          <div class="mb-6">
            <label for="department_id" class="block text-gray-700 font-bold mb-2">Department</label>
            <select
              id="department_id"
              class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
              v-model="form.department_id"
            >
              <option v-for="department in departments" :key="department.id" :value="department.id">
                {{ department.name }}
              </option>
            </select>
            <p v-if="errors.department_id" class="text-red-500 text-sm mt-1">{{ errors.department_id.join(' ') }}</p>
          </div>

          <div class="mb-6">
            <label for="employment_type_id" class="block text-gray-700 font-bold mb-2">Employment Type</label>
            <select
              id="employment_type_id"
              class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
              v-model="form.employment_type_id"
            >
              <option v-for="employment_type in employment_types" :key="employment_type.id" :value="employment_type.id">
                {{ employment_type.name }}
              </option>
            </select>
            <p v-if="errors.employment_type_id" class="text-red-500 text-sm mt-1">{{ errors.employment_type_id.join(' ') }}</p>
          </div>

          <div class="mb-6">
            <label for="tsc_number" class="block text-gray-700 font-bold mb-2">TSC Number</label>
            <input
              type="text"
              id="tsc_number"
              class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
              v-model="form.tsc_number"
            />
            <p v-if="errors.tsc_number" class="text-red-500 text-sm mt-1">{{ errors.tsc_number.join(' ') }}</p>
          </div>

          <div class="mb-6">
            <label for="gender" class="block text-gray-700 font-bold mb-2">Gender</label>
            <select
              id="gender"
              class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
              v-model="form.gender"
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            <p v-if="errors.gender" class="text-red-500 text-sm mt-1">{{ errors.gender.join(' ') }}</p>
          </div>
        </div>

        <!-- Password fields -->
        <div class="mb-6">
          <label for="password" class="block text-gray-700 font-bold mb-2">Password</label>
          <input
            type="password"
            id="password"
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
            v-model="form.password"
            required
          />
          <p v-if="errors.password" class="text-red-500 text-sm mt-1">{{ errors.password.join(' ') }}</p>
        </div>

        <div class="mb-6">
          <label for="password_confirmation" class="block text-gray-700 font-bold mb-2">Confirm Password</label>
          <input
            type="password"
            id="password_confirmation"
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
            v-model="form.password_confirmation"
            required
          />
          <p v-if="errors.password_confirmation" class="text-red-500 text-sm mt-1">{{ errors.password_confirmation.join(' ') }}</p>
        </div>

        <div class="mb-6">
          <button type="submit" class="bg-laravel text-white py-3 px-6 rounded-md hover:bg-black focus:outline-none focus:bg-black transition-colors duration-300 w-full">Register</button>
        </div>

        <div class="mt-8 text-center">
          <p>
            Have an account?
            <a href="/login" class="text-blue-600 hover:underline">Login</a>
          </p>
        </div>
      </form>
    </div>
    <div class="errors"><p v-for="(error,index) in errors" :key="index">{{ error }}</p></div>
  </LayoutComp>
</template>

<script>
import axios from '../../axios';
import LayoutComp from '@/components/LayoutComp.vue';
import SpinnerComp from '@/components/SpinnerComp.vue'; // Import the Spinner component

export default {
  name: 'RegisterPage',
  components: { LayoutComp, SpinnerComp }, // Include the SpinnerComp in the components object
  data() {
    return {
      token: '',
      position: '',
      form: {
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        department_id: '',
        employment_type_id: '',
        tsc_number: '',
        gender: '', // Add gender field
        password: '',
        password_confirmation: '',
      },
      departments: [],
      employment_types: [],
      errors: {},
      loading: false // Add loading state for spinner
    };
  },
  created() {
    this.token = this.$route.query.token || '';
    this.position = this.$route.query.position || '';
    this.fetchInviteDetails();
  },
  methods: {
    fetchInviteDetails() {
      this.loading = true; // Show spinner while fetching invite details
      console.log(this.token);
      axios.get(`/users/register/${this.token}`)
        .then(response => {
          this.position = response.data.position;
          this.form.email = response.data.email;
          if (this.position === 'teacher') {
            this.departments = response.data.departments;
            this.employment_types = response.data.employment_types;
          }
          console.log(response.data);
        })
        .catch(error => {
          console.error('Error fetching invite details:', error);
        })
        .finally(() => {
          this.loading = false; // Hide spinner after request completes
        });
    },
    registerUser() {
      this.loading = true; // Show spinner while registering user
      const data = { ... this.form,
                    position:this.$route.query.position,
                    invitation_token : this.$route.query.token
                  };
      axios.post(`/users/register`, data)
        .then(response => {
          console.log(response.data);
          // Redirect to login page after successful registration
          this.$router.push('/login');
        })
        .catch(error => {
          console.log(error);
          if (error.response && error.response.data) {
            this.errors = error.response.data.errors;
          } else {
            console.error('Error registering user:', error);
          }
        })
        .finally(() => {
          this.loading = false; // Hide spinner after request completes
        });
    }
  }
};
</script>


