<template>
  <div v-if="loading" class="spinner-overlay">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpinnerComp',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.spinner {
  margin: 100px auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 16px;
  height: 16px;
  background-color: #e3342f; /* Red color */
  border-radius: 50%;
  display: inline-block;
  animation: bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
  background-color: #e3342f; /* Red */
}

.spinner .bounce2 {
  animation-delay: -0.16s;
  background-color: #333; /* Black */
}

.spinner .bounce3 {
  background-color: #e3342f; /* Red */
}

@keyframes bouncedelay {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1.0); }
}
</style>
s