<template>
    <div class="max-w-xl mx-auto p-6 bg-white rounded">
      <SpinnerComp :loading="loading" />
  
      <form @submit.prevent="submitResults">
        <div class="mb-6">
          <label for="grade" class="block text-gray-700 font-bold mb-2">Select Grade:</label>
          <select v-model="selectedGrade" id="grade" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
            <option disabled value="">Select a grade</option>
            <option v-for="grade in grades" :key="grade.id" :value="grade.id">{{ grade.grade }} => {{ grade.points }}</option>
          </select>
        </div>
  
        <div class="mb-6">
          <label for="comment" class="block text-gray-700 font-bold mb-2">Enter Comment:</label>
          <input type="text" v-model="comment" id="comment" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600" />
        </div>
  
        <div class="mb-6">
          <button @click.prevent="addResult" class="py-2 px-4 bg-blue-500 text-white rounded-lg mt-2 focus:outline-none hover:bg-blue-600">Add Comment</button>
        </div>
  
        <div class="mb-6">
          <label v-if="results.length > 0" class="block text-gray-700 font-bold mb-2">Added Comments:</label>
          <p v-if="results.length === 0" class="text-gray-500">No comments added.</p>
          <ul>
            <li v-for="result in results" :key="result.grade.id">
              {{ result.grade.grade }} => {{ result.grade.points }} : {{ result.comment }}
              <button @click.prevent="removeResult(result.grade.id)" class="ml-2 text-red-500">Remove</button>
            </li>
          </ul>
        </div>
  
        <div class="flex justify-end">
          <button type="submit" class="bg-red-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-red-600 transition-colors duration-300">Submit Comments</button>
        </div>
      </form>
  
      <div v-if="error" class="text-red-500 mt-4">
        Error: {{ error }}
      </div>
    </div>
  </template>
  
  <script>
  import axios from '@/axios';
  import SpinnerComp from '@/components/SpinnerComp.vue';
import { mapActions } from 'vuex';
  
  export default {
    components: {
      SpinnerComp,
    },
    data() {
      return {
        loading: false,
        grades: [],
        selectedGrade: null,
        comment: '',
        results: [],
        error: '',
      };
    },
    mounted() {
      this.fetchGrades();
    },
    methods: {
      ...mapActions('exams',['fetchRecords']),
      fetchGrades() {
        this.loading = true;
        axios.get('/grades')
          .then(response => {
            this.grades = response.data || [];
          })
          .catch(error => {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          }).finally(()=>{
            this.loading = false;
          });
      },
      addResult() {
        if (!this.selectedGrade || !this.comment) {
          this.error = 'Please select a grade and enter a comment.';
          return;
        }
        const grade = this.grades.find(grade => grade.id === this.selectedGrade);
        if (grade) {
          this.results.push({ grade, comment: this.comment });
          this.selectedGrade = null;
          this.comment = '';
        }
      },
      removeResult(gradeId) {
        this.results = this.results.filter(result => result.grade.id !== gradeId);
      },
      submitResults() {
        if (!this.results.length) {
          this.error = 'Please add at least one comment.';
          return;
        }
  
        this.loading = true;
        const payload = {
          results: this.results.map(result => ({
            grade_id: result.grade.id,
            comment: result.comment,
          })),
        };
        axios.post('/schedule/grade-comments', payload)
          .then(() => {
            alert('Comments submitted successfully!');
            this.fetchRecords();
            this.resetForm();
          })
          .catch(error => {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      resetForm() {
        this.selectedGrade = null;
        this.comment = '';
        this.results = [];
        this.error = null;
      },
    },
  };
  </script>
  