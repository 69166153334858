import { createStore } from "vuex";
import community from "./modules/community";
import schedule from "./modules/schedule";
import house from './modules/house';
import admin from './modules/admin';
import academics  from "./modules/academics";
import exams from './modules/exams';

export default createStore({
    modules : {
     community,
     schedule,
     house,
     admin,
     academics,
     exams
    } 
 });