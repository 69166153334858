<template>
    <div class="container mx-auto p-8 bg-white shadow-md rounded-lg">
      <h2 class="text-2xl font-bold mb-6 text-center text-gray-800">
        {{ formattedActiveType }}
      </h2>

      <router-link to="/dashboard" class="mb-4 flex items-center hover:text-red-400 transition">
          <font-awesome-icon :icon="['fas', 'house']"  class="mr-2"/>
          Home
      </router-link>
  
      <!-- Search Component -->
      <SearchComponent @search="updateSearch" />
  
      <!-- Toggle Buttons Component -->
      <ToggleButtons
        :types="['subjects','subject enrollments']"
        :activeType="activeType"
        @update-type="updateType"
        class="mb-4"
      />
  
      <!-- New EntityForm Button -->
      <button @click="openForm" class="py-2 px-6 mb-6 bg-blue-500 text-white rounded-lg mt-4">
        New {{ formattedActiveType }}
      </button>
  
      <!-- Record Table Component -->
      <RecordTable
        :members="records"
        :sortField="sortField"
        :sortDirection="sortDirection"
        @sort="updateSort"
        @edit="editRecord"
      />
   
      <!-- Pagination Component -->
      <PaginationComp
        :currentPage="currentPage"
        :lastPage="lastPage"
        @page-change="updatePage"
      />
  
      <!-- EntityForm Modal -->
      <AcademicEntity
        v-if="formOpen"
        :activeType="activeType"
        :id="selectedId"
        @close="formOpen = false"
        @success="handleSuccess"
      />
  
      <!-- Error Message -->
      <div v-if="error" class="text-red-500 mt-4">
        Error: {{ error }}
      </div>
  
      <!-- Loading Spinner-->
      <SpinnerComp :loading="loading" />
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import SearchComponent from '@/components/SearchComponent.vue';
  import ToggleButtons from '@/components/ToggleButtons.vue';
  import RecordTable from '@/components/RecordTable.vue';
  import PaginationComp from '@/components/PaginationComp.vue';
  import SpinnerComp from '@/components/SpinnerComp.vue';
  import AcademicEntity from '@/components/forms/AcademicEntity.vue';
  import axios from '@/axios';
  
  export default {
    components: {
      SearchComponent,
      ToggleButtons,
      RecordTable,
      AcademicEntity,
      PaginationComp,
      SpinnerComp
    },
    async mounted() {
      await this.fetchRecords();
    },
    data() {
      return {
        formOpen: false,
        selectedId: null
      };
    },
    computed: {
      ...mapGetters('academics', ['records', 'activeType', 'sortField', 'sortDirection', 'currentPage', 'lastPage', 'error', 'loading']),
      formattedActiveType() {
        return this.activeType ? this.activeType.charAt(0).toUpperCase() + this.activeType.slice(1) : '';
      }
    },
    methods: {
      ...mapActions('academics', ['fetchRecords', 'setActiveType',
       'setSearchTerm', 'setSort', 'setPage','setError']),
      updateType(type) {
        this.setActiveType(type);
      },
      updateSearch(term) {
        this.setSearchTerm(term);
      },
      updateSort({ field, direction }) {
        this.setSort({ field, direction });
      },
      updatePage(page) {
        this.setPage(page);
      },
      openForm() {
        this.selectedId = null;
        this.formOpen = true;
      },
      editRecord(id) {
        this.selectedId = id;
        this.formOpen = true;
      },deleteRecord(id) {
        if (!confirm('Are you sure you want to delete this record?')) {
        return;
        }
        this.setLoading(true);
        let url = '';
        if (this.activeType === 'subjects') {
          url = `/subjects/${id}/delete`;
        }else if(this.activeType === 'subject enrollments'){
          url = `/subject-enrollments/${id}/delete`;
        }
        axios.post(url).then(
          async () => {
            await this.fetchRecords();
          }
        ).catch((error)=> {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.setError(errorMessage);
        }).finally(() => {
          this.setLoading(false);
        }
        );
      },

      handleSuccess() {
        this.formOpen = false;
        this.fetchRecords();
      }
    }
  };
  </script>
  
  
  