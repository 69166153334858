<template>
  <div class="flex space-x-4">
    <button
      v-for="type in types"
      :key="type"
      @click="setActiveType(type)"
      :class="[
        'px-4 py-2 rounded-lg focus:outline-none',
        activeType === type ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
      ]"
    >
      {{ type.charAt(0).toUpperCase() + type.slice(1) }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    types: Array,
    activeType: String,
  },
  methods: {
    setActiveType(type) {
      this.$emit('update-type', type);
    },
  },
};
</script>
