<template>
  <div class="container mx-auto p-8 bg-white shadow-md rounded-lg">
    <h2 class="text-2xl font-bold mb-6 text-center text-gray-800">
      {{ formattedActiveType }}
    </h2>

    <router-link to="/dashboard" class="mb-4 flex items-center hover:text-red-400 transition">
      <font-awesome-icon :icon="['fas', 'house']"  class="mr-2"/>
      Home
    </router-link>

    <!-- Search Component -->
    <SearchComponent @search="updateSearch" />

    <!-- Toggle Buttons Component -->
    <ToggleButtons
      :types="['students', 'teachers']"
      :activeType="activeType"
      @update-type="updateType"
      class="mb-4"
    />

    <!-- New EntityForm Button -->
    <button
      @click="openForm"
      class="py-2 px-6 mb-6 bg-gray-500 hover:bg-black text-white rounded-lg mt-4"
    >
      New {{ formattedActiveType }}
    </button>

    <!-- Manual Register Button -->
    <button
      @click="manualFormOpen = true;"
      class="py-2 px-6 mb-6 bg-gray-500 hover:bg-black text-white rounded-lg mt-4 ml-2"
      v-if="activeType==='teachers'"
    >
      Manual Register
    </button>

    <!-- Record Table Component -->
    <RecordTable
      :members="members"
      :sortField="sortField"
      :sortDirection="sortDirection"
      @sort="updateSort"
      @edit="editRecord"
      @delete="deleteRecord"
    />

    <!-- Pagination Component -->
    <PaginationComp
      :currentPage="currentPage"
      :lastPage="lastPage"
      @page-change="updatePage"
    />

    <!-- EntityForm Modal -->
    <EntityForm
      v-if="formOpen"
      :activeType="activeType"
      :id="selectedId"
      @close="formOpen = false"
    />

    <!-- Manual Teacher Registration Modal -->
    <ManualTeacherReg v-if="manualFormOpen" @close="manualFormOpen = false" />

    <!-- Error Message -->
    <div v-if="error" class="text-red-500 mt-4">
      Error: {{ error }}
    </div>

    <!-- Loading Spinner-->
     <SpinnerComp :loading="loading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SearchComponent from '../../components/SearchComponent.vue';
import ToggleButtons from '../../components/ToggleButtons.vue';
import RecordTable from '../../components/RecordTable.vue';
import EntityForm from '../../components/forms/EntityForm.vue';
import PaginationComp from '@/components/PaginationComp.vue';
import SpinnerComp from '@/components/SpinnerComp.vue';
import ManualTeacherReg from '@/components/forms/ManualTeacherReg.vue';
import axios from '@/axios';

export default {
  components: {
    SearchComponent,
    ToggleButtons,
    RecordTable,
    EntityForm,
    PaginationComp,
    SpinnerComp,
    ManualTeacherReg
  },
  mounted() {
    this.fetchMembers();
  },
  data() {
    return {
      formOpen: false,
      selectedId : null,
      manualFormOpen: false,
    };
  },
  computed: {
    ...mapGetters('community', ['members', 
    'activeType', 'sortField', 'sortDirection',
    'currentPage', 'lastPage', 'error','loading']),

    formattedActiveType() {
      return this.activeType ? this.activeType.charAt(0).toUpperCase() + this.activeType.slice(1) : '';
    }
  },
  methods: {
    ...mapActions('community', ['fetchMembers',
    'setActiveType', 'setSearchTerm', 'setSort', 'setPage','setLoading']),
    updateType(type) {
      this.setActiveType(type);
    },
    updateSearch(term) {
      this.setSearchTerm(term);
    },
    updateSort({ field, direction }) {
      this.setSort({ field, direction });
    },
    updatePage(page) {
      this.setPage(page);
    },
    openForm() {
      this.formOpen = true;
    },
    editRecord(id) {
      this.selectedId = id;
      this.formOpen = true;
    },
    async deleteRecord(id) {
      if (this.activeType === 'students') {
        try {
          this.setLoading(true);
          await axios.post(`/students/${id}/delete`);
          await this.fetchMembers();
        }catch(error) {
          console.error('Error deleting student record',error);
        }finally {
          this.setLoading(false);
        }
      }else if(this.activeType == 'teachers') {
        try {
          this.setLoading(true);
          await axios.post(`/teachers/${id}/delete`);
          await this.fetchMembers();
        }catch(error) {
          console.error('Error deleting teacher record',error);
        }finally {
          this.setLoading(false);
        }
      }
    }
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}
</style>
