<template>
  <ModalLayout @close="closeModal">
    <div class="relative max-w-3xl mx-auto bg-white rounded">
      <!-- Close Icon -->
      <button @click="closeModal" class="absolute top-0 right-0 mt-2 mr-2 text-gray-600 hover:text-red-600 focus:outline-none text-xl">
        <font-awesome-icon icon="times" />
      </button>
      <!-- Dynamic Form Header -->
      <h2 class="text-2xl text-center font-bold mb-6">{{ getFormTitle() }}</h2>

      <!-- Dynamic Form -->
      <component :is="getActiveFormComponent()" :id="id" @close="closeModal" @success="handleSuccess" />

      <!-- Placeholder for Development -->
      <p v-if="!getActiveFormComponent()" class="text-center text-gray-600 mt-8">Form component development in progress...</p>
    </div>
  </ModalLayout>
</template>

<script>
import ModalLayout from '../ModalLayout.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import AcademicYearForm from './AcademicYearForm.vue';
import TermForm from './TermForm.vue';
import ClassForm from './ClassForm.vue';
import ApproveProgression from './ApproveProgression.vue';
import StudentEnrollmentForm from './StudentEnrollmentForm.vue';
import StreamForm from './StreamForm.vue';

export default {
  components: {
    ModalLayout,
    'font-awesome-icon': FontAwesomeIcon,
    AcademicYearForm,
    TermForm,
    ClassForm,
    ApproveProgression,
    StudentEnrollmentForm,
    StreamForm
  },
  props: {
    activeType: {
      type: String,
      required: true
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  methods: {
    getActiveFormComponent() {
      switch (this.activeType) {
        case 'academic years':
          return 'AcademicYearForm';
        case 'terms':
          return 'TermForm';
        case 'classes':
          return 'ClassForm';
        case 'class enrollments':
          return 'ClassEnrollmentForm';
        case 'student enrollments':
          return 'StudentEnrollmentForm';
        case 'progressions':
          return 'ApproveProgression';
        case 'streams':
          return 'StreamForm';
        default:
          return null;
      }
    },
    getFormTitle() {
      switch (this.activeType) {
        case 'academic years':
          return this.id ? 'Edit Academic Year' : 'Create New Academic Year';
        case 'terms':
          return this.id ? 'Edit Term' : 'Create New Term';
        case 'classes':
          return this.id ? 'Edit Class' : 'Create New Form Classes';
        case 'progressions':
          return 'Progress Classes';
        case 'student enrollments':
          return this.id ? 'Edit Student Enrollment' : 'Enroll Student to Class';
        case 'streams':
          return this.id ? 'Edit Stream' : 'Create Stream';
        default:
          return '';
      }
    },
    closeModal() {
      this.$emit('close');
    },
    handleSuccess() {
      this.$emit('success');
      this.closeModal();
    }
  }
};
</script>
