<template>
    <LayoutComp>
      <SpinnerComp :loading="loading" />
      <div class="p-10 max-w-lg mx-auto mb-34 bg-white rounded-lg shadow-lg">
        <header class="text-center">
          <h2 class="text-3xl font-bold mb-4">Reset Password</h2>
          <p class="mb-6">Enter your new password below</p>
        </header>
        <form @submit.prevent="resetPassword">
          <!-- Error Message -->
          <p v-if="errors.message" class="text-red-500 text-center text-sm mb-4">{{ errors.message }}</p>
  
          <!-- Success Message -->
          <p v-if="successMessage" class="text-green-500 text-center text-sm mb-4">{{ successMessage }}</p>
          
          <div class="mb-6">
            <label for="email" class="block text-gray-700 font-bold mb-2">Email</label>
            <input
              type="email"
              id="email"
              class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
              v-model="form.email"
              readonly
            />
          </div>
  
          <div class="mb-6">
            <label for="password" class="block text-gray-700 font-bold mb-2">New Password</label>
            <input
              type="password"
              id="password"
              class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
              v-model="form.password"
            />
            <p v-if="errors.password" class="text-red-500 text-sm mt-1">{{ errors.password.join(' ') }}</p>
          </div>
  
          <div class="mb-6">
            <label for="password_confirmation" class="block text-gray-700 font-bold mb-2">Confirm Password</label>
            <input
              type="password"
              id="password_confirmation"
              class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
              v-model="form.password_confirmation"
            />
            <p v-if="errors.password_confirmation" class="text-red-500 text-sm mt-1">{{ errors.password_confirmation.join(' ') }}</p>
          </div>
  
          <div class="mb-6">
            <button type="submit" class="bg-laravel text-white py-3 px-6 rounded-md hover:bg-black focus:outline-none focus:bg-black transition-colors duration-300 w-full">
              Reset Password
            </button>
          </div>
        </form>
        <!-- Router Link for Login -->
        <div class="mt-6 text-center">
          <router-link
            to="/login"
            class="text-sm text-blue-500 hover:text-blue-700"
          >
            Login
          </router-link>
        </div>
      </div>
    </LayoutComp>
  </template>
  
  <script>
  import axiosInstance from '../../axios'; // Your axios instance with the '/api' prefix
  import axios from 'axios'; // Plain axios for the CSRF token request
  import LayoutComp from '@/components/LayoutComp.vue';
  import SpinnerComp from '@/components/SpinnerComp.vue';
  
  export default {
    name: 'ResetPasswordPage',
    components: { LayoutComp, SpinnerComp },
    data() {
      return {
        form: {
          token: '',
          email: '',
          password: '',
          password_confirmation: ''
        },
        errors: {},
        successMessage: '',
        loading: false
      };
    },
    created() {
      // Extract email from query parameters
      const urlParams = new URLSearchParams(window.location.search);
      this.form.email = urlParams.get('email');
      // Extract token from the URL path
      this.form.token = urlParams.get('token');
    },
    methods: {
      resetPassword() {
        this.loading = true;
        this.errors = {};
        this.successMessage = '';
  
        // Fetch CSRF token using plain axios
        axios.get(process.env.VUE_APP_CSRF_URL, {
          withCredentials: true
        })
        .then(() => {
          // Make the reset password API call
          return axiosInstance.post('/users/reset-password', this.form);
        })
        .then(response => {
          // Handle successful password reset
          this.successMessage = 'Your password has been reset successfully.';
          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
          // Handle validation errors or other errors
          if (error.response && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else if (error.response && error.response.status === 422) {
            this.errors = { message: 'Invalid input data.' };
          } else {
            this.errors = { message: 'Something went wrong. Please try again later.' };
          }
        })
        .finally(() => {
          this.loading = false;
        });
      }
    }
  };
  </script>
  