<template>
  <footer class="bg-laravel text-white w-full h-24 flex items-center justify-center">
    <p class="ml-2">Copyright &copy; 2024, All Rights Reserved</p>
  </footer>
</template>

<script>
  export default {
    name: 'FooterComp',
  };
</script>

