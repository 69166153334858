<template>
  <div class="max-w-xl mx-auto p-6 bg-white rounded">
    <SpinnerComp :loading="loading" />

    <form @submit.prevent="submitEnrollment">
      <div class="mb-6">
        <label for="class" class="block text-gray-700 font-bold mb-2">Select Class:</label>
        <select v-model="selectedClass" id="class" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select a class</option>
          <option v-for="classItem in classes" :key="classItem.id" :value="classItem.id">{{ classItem.name }}</option>
        </select>
      </div>

      <div class="mb-6">
          <label for="student" class="block text-gray-700 font-bold mb-2">Select Student:</label>
          <ItemFilter :items="studentList" label="Student" @item-selected="selectedStudent = $event"/>
          <button @click.prevent="addStudent" class="py-2 px-4 bg-blue-500 text-white rounded-lg mt-2 focus:outline-none hover:bg-blue-600">Add Student</button>
      </div>

      <div class="mb-6">
        <label v-if="selectedStudents.length > 0" class="block text-gray-700 font-bold mb-2">Selected Students:</label>
        <p v-if="selectedStudents.length === 0" class="text-gray-500">No students selected.</p>
        <ul>
          <li v-for="student in selectedStudents" :key="student.id">
            {{ student.id }} - {{ student.first_name }} {{ student.last_name }}
            <button @click.prevent="removeStudent(student.id)" class="ml-2 text-red-500">Remove</button>
          </li>
        </ul>
      </div>

      <div class="flex justify-end">
        <button type="submit" class="bg-red-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-red-600 transition-colors duration-300">Enroll Students</button>
      </div>
    </form>

    <div v-if="error" class="text-red-500 mt-4">
      Error: {{ error }}
    </div>
  </div>
</template>

<script>
  import axios from '@/axios';
  import SpinnerComp from '@/components/SpinnerComp.vue';
  import ItemFilter from '@/components/ItemFilter.vue';

  export default {
    components: {
      SpinnerComp,
      ItemFilter
    },
    data() {
      return {
        loading: false,
        students: [],
        selectedStudent: null,
        selectedStudents: [],
        classes: [],
        selectedClass: null,
        error: '',
      };
    },
    computed : {
      studentList() {
        return Array.isArray(this.students) ? this.students.map((student) => {
          return {
            'id' : student.id,
            'name' : `${student.first_name} ${student.middle_name ? student.middle_name : ''} ${student.last_name}`
          }
        }) : [];
      }
    },
    methods: {
      fetchEnrolledClasses() {
        this.loading = true;
        axios.get('/schedule/classes/enrolled')
          .then(response => {
            this.classes = response.data;
          })
          .catch(error => {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          }).finally(()=>{
            this.loading = false;
          });
      },
      fetchActiveStudents() {
        this.loading = true;
        axios.get('/schedule/students/active')
          .then(response => {
            this.students = response.data;
          })
          .catch(error => {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          }).finally(()=>{
            this.loading = false;
          });
      },
      addStudent() {
        if (this.selectedStudent) {
          const student = this.students.find(student => student.id === this.selectedStudent);
          this.selectedStudents.push(student);
          this.selectedStudent = null;
        }
      },
      removeStudent(studentId) {
        this.selectedStudents = this.selectedStudents.filter(student => student.id !== studentId);
      },
      submitEnrollment() {
        if (!this.selectedStudents.length) {
          this.error = 'Please select at least one student.';
          return;
        }
        if (!this.selectedClass) {
          this.error = 'Please select a class.';
          return;
        }

        this.loading = true;
        const payload = {
          student_ids: this.selectedStudents.map(student => student.id),
          class_id: this.selectedClass,
        };

        axios.post('/schedule/enrollments', payload)
          .then(() => {
            alert('Enrollment successful!');
            this.resetForm();
          })
          .catch(error => {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      resetForm() {
        this.selectedStudent = null;
        this.selectedStudents = [];
        this.selectedClass = null;
        this.error = null;
      },
    },
    mounted() {
      this.fetchEnrolledClasses();
      this.fetchActiveStudents();
    },
  };

</script>
