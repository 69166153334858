<template>
  <div class="overflow-x-auto">
    <table class="min-w-full bg-white border border-gray-200 rounded-lg">
      <thead class="bg-gray-100">
        <tr>
          <th
            v-for="header in resolvedHeaders"
            :key="header"
            @click="sort(header)"
            class="border px-6 py-3 text-left text-sm font-medium text-gray-800 uppercase tracking-wider cursor-pointer"
          >
            <div class="flex items-center">
              <span>{{ formatHeader(header) }}</span>
              <font-awesome-icon
                v-if="currentSortField === header"
                :icon="currentSortDirection === 'asc' ? 'sort-up' : (currentSortDirection === 'desc' ? 'sort-down' : '')"
                class="ml-1"
              />
            </div>
          </th>
          <!-- Add an extra header for the edit button column -->
          <th class="border px-6 py-3 text-left text-sm font-medium text-gray-800 uppercase tracking-wider">
            Actions
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr v-for="record in members" :key="record.id">
          <td v-for="header in resolvedHeaders" :key="header" class="border px-6 py-4">
            {{ record[header.toLowerCase()] }}
          </td>
          <!-- Add an extra cell for the edit button -->
          <td class="border px-6 py-4 flex items-center">
            <button @click="$emit('edit', record.id)" class="text-blue-500 hover:underline mr-2">
              Edit
            </button>
            <button @click="$emit('delete',record.id)" class="text-red-400 hover:underline">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: null,
    },
    members: {
      type: Array,
      required: true,
    },
    sortField: {
      type: String,
      default: '',
    },
    sortDirection: {
      type: String,
      default: '',
    },
  },
  computed: {
    resolvedHeaders() {
      // Check if `this.headers` is defined and has a length greater than 0
      if (Array.isArray(this.headers) && this.headers.length > 0) {
        return this.headers;
      }

      // Check if `this.members` is defined and has a length greater than 0
      if (Array.isArray(this.members) && this.members.length > 0) {
        // Use Object.keys() on the first member to get the header names
        return Object.keys(this.members[0]);
      }

      // Default to an empty array if neither condition is met
      return [];
    },
    currentSortField() {
      return this.sortField;
    },
    currentSortDirection() {
      return this.sortDirection;
    }
  },
  methods: {
    sort(header) {
      let direction = 'asc';
      if (this.currentSortField === header) {
        if (this.currentSortDirection === 'asc') {
          direction = 'desc';
        } else if (this.currentSortDirection === 'desc') {
          direction = '';
        }
      }
      this.$emit('sort', { field: header, direction });
    },
    formatHeader(header) {
      return header.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }
  }
};
</script>
