<template>  
    <div class="relative max-w-xl mx-auto p-6 bg-white rounded"> 
      <SpinnerComp :loading="loading" /> 
      <!-- Form for inviting user -->
      <form @submit.prevent="sendInvite">
        <div class="mb-6">
          <label for="email" class="block text-gray-700 font-bold mb-2">Email:</label>
          <input type="email" v-model="email" id="email" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <p v-if="errors.email" class="text-red-500 text-sm mt-1">{{ errors.email.join(' ') }}</p>
        </div>

        <div class="mb-6" v-if="activeType === 'teachers' || activeType === 'staff'">
          <label for="position" class="block text-gray-700 font-bold mb-2">Position:</label>
          <select v-model="position" id="position" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
            <option value="staff">Staff</option>
            <option value="teacher">Teacher</option>
          </select>
          <p v-if="errors.position" class="text-red-500 text-sm mt-1">{{ errors.position.join(' ') }}</p>
        </div>

        <div class="flex justify-end">
          <button type="submit" class="bg-red-500 hover:bg-black text-white py-3 px-6 rounded-md  focus:outline-none focus:bg-red-600 transition-colors duration-300">Send Invite</button>
        </div>
      </form>
      <!-- Error Message -->
      <div v-if="error" class="text-red-500 mt-4">
        Error: {{ error }}
      </div>
    </div>
</template>

<script>
import axios from '../../axios';
import SpinnerComp from '@/components/SpinnerComp.vue';

export default {
  components: {
    SpinnerComp,
  },
  props: {
    activeType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      position: this.activeType, // Set initial position based on activeType
      errors: {},
      loading: false,
      error:''
    };
  },
  methods: {
    closeForm() {
      this.$emit('close');
    },
    sendInvite() {
      this.loading = true;
      axios.post('/users/invite', {
        email: this.email,
        position: this.position,
      })
      .then(() => {
        alert('Invitation Successfully Sent');
      })
      .catch(error => {
        console.log(error);
        if (error.response && error.response.data.errors) {
          this.errors = error.response.data.errors;
        } else {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
