<template>
  <div 
    class="bg-white rounded-lg shadow-md p-4 flex flex-col items-center text-center transition-transform transform hover:scale-105 cursor-pointer"
    @click="route && navigateTo(route)"
  >
    <div class="text-4xl text-gray-600 mb-4">
      <font-awesome-icon :icon="icon" />
    </div>
    <div class="text-xl font-semibold mb-2">
      {{ title }}
    </div>
    <div class="text-gray-600">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    icon: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    route: {
      type: String,
      default: ''
    }
  },
  methods: {
    navigateTo(route) {
      this.$router.push({name:route});
    }
  }
};
</script>
