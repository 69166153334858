<template>
    <ModalLayout>
        <div class="relative max-w-md mx-auto bg-white rounded">
            <h2 class="font-bold mx-auto mb-2 text-lg">Register Teacher</h2>
            <!-- Close Icon -->
            <button @click="closeModal" class="absolute top-0 
                    right-0 mt-2 mr-2 text-gray-600 hover:text-red-600 focus:outline-none text-xl">
                <font-awesome-icon icon="times" />
            </button>
            <SpinnerComp :loading="loading" />
            <div class="mb-6">
            <label for="first_name" class="block text-gray-700 font-bold mb-2">First Name</label>
            <input
                type="text"
                id="first_name"
                class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                v-model="form.first_name"
            />
            <p v-if="errors.first_name" class="text-red-500 text-sm mt-1">{{ errors.first_name.join(' ') }}</p>
            </div>

            <div class="mb-6">
            <label for="last_name" class="block text-gray-700 font-bold mb-2">Last Name</label>
            <input
                type="text"
                id="last_name"
                class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                v-model="form.last_name"
            />
            <p v-if="errors.last_name" class="text-red-500 text-sm mt-1">{{ errors.last_name.join(' ') }}</p>
            </div>

            <div class="mb-6">
            <label for="email" class="block text-gray-700 font-bold mb-2">Email</label>
            <input
                type="email"
                id="email"
                class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                v-model="form.email"
            />
            <p v-if="errors.email" class="text-red-500 text-sm mt-1">{{ errors.email.join(' ') }}</p>
            </div>

            <div class="mb-6">
            <label for="phone_number" class="block text-gray-700 font-bold mb-2">Phone Number</label>
            <input
                type="text"
                id="phone_number"
                class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                v-model="form.phone_number"
            />
            <p v-if="errors.phone_number" class="text-red-500 text-sm mt-1">{{ errors.phone_number.join(' ') }}</p>
            </div>

            <div class="mb-6">
                <label for="department_id" class="block text-gray-700 font-bold mb-2">Department</label>
                <select
                id="department_id"
                class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                v-model="form.department_id"
                >
                <option v-for="department in departments" :key="department.id" :value="department.id">
                    {{ department.name }}
                </option>
                </select>
                <p v-if="errors.department_id" class="text-red-500 text-sm mt-1">{{ errors.department_id.join(' ') }}</p>
            </div>

            <div class="mb-6">
                <label for="employment_type_id" class="block text-gray-700 font-bold mb-2">Employment Type</label>
                <select
                id="employment_type_id"
                class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                v-model="form.employment_type_id"
                >
                <option v-for="employment_type in employment_types" :key="employment_type.id" :value="employment_type.id">
                    {{ employment_type.name }}
                </option>
                </select>
                <p v-if="errors.employment_type_id" class="text-red-500 text-sm mt-1">{{ errors.employment_type_id.join(' ') }}</p>
            </div>

            <div class="mb-6">
                <label for="tsc_number" class="block text-gray-700 font-bold mb-2">TSC Number</label>
                <input
                type="text"
                id="tsc_number"
                class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                v-model="form.tsc_number"
                />
                <p v-if="errors.tsc_number" class="text-red-500 text-sm mt-1">{{ errors.tsc_number.join(' ') }}</p>
            </div>

            <div class="mb-6">
                <label for="gender" class="block text-gray-700 font-bold mb-2">Gender</label>
                <select
                id="gender"
                class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                v-model="form.gender"
                >
                <option value="male">Male</option>
                <option value="female">Female</option>
                </select>
                <p v-if="errors.gender" class="text-red-500 text-sm mt-1">{{ errors.gender.join(' ') }}</p>
            </div>
            <button class="bg-laravel text-white hover:bg-black p-2 rounded" @click.prevent="registerTeacher">
                Submit
            </button>
        </div>
    </ModalLayout>

</template>


<script>
    import ModalLayout from '@/components/ModalLayout.vue';
    import SpinnerComp from '@/components/SpinnerComp.vue';
    import axios from '@/axios';
    export default {
        components : {
            ModalLayout,
            SpinnerComp
        },
        data() {
            return {
                form: {
                    email: '',
                    first_name: '',
                    last_name: '',
                    phone_number: '',
                    department_id: '',
                    employment_type_id: '',
                    tsc_number: '',
                    gender: '',
                },
                departments: [],
                employment_types: [],
                errors: {},
                loading: false // Add loading state for spinner      
            }
        },
        async mounted() {
            this.loading = true;
            await this.fetchDepartments();
            await this.fetchEmploymentTypes();
            this.loading = false;
        },
        methods: {
            async fetchDepartments() {
                const url = '/departments';
                this.loading = true;
                try {
                    const response = await axios.get(url);
                    this.departments = response.data;
                }catch(error) {
                    console.error('Error fetcing deparments',error);
                }finally {
                    this.loading = false;
                }
            },
            async fetchEmploymentTypes() {
                const url = '/employment-types';
                this.loading = true;
                try {
                    const response = await axios.get(url);
                    this.employment_types = response.data;
                }catch(error) {
                    console.error('Error fetching Employment Types',error);
                }finally {
                    this.loading = false;
                }
            },
            closeModal() {
                this.$emit('close');
            },
            async registerTeacher() {
                console.log('Register');
                const url = '/users/register/manual';
                this.loading = true;
                try {
                    await axios.post(url,this.form);
                    alert('Teacher Registered Successfully');
                }catch(error) {
                    if (error.response && error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    } else {
                        console.error('Error registering teacher:', error);
                    }
                }finally {
                    this.loading = false;
                }
            }
        }
    }
</script>