<template>
  <div class="relative max-w-xl mx-auto p-6 bg-white rounded">
    <SpinnerComp :loading="loading" />

    <!-- Form for creating or editing a subject -->
    <form @submit.prevent="handleSubmit">
      <div class="mb-6">
        <label for="subjectName" class="block text-gray-700 font-bold mb-2">Subject Name:</label>
        <input 
          type="text" 
          v-model="subjectName" 
          id="subjectName" 
          class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
        <p v-if="errors.subject" class="text-red-500 text-sm mt-1">{{ errors.subject.join(' ') }}</p>
      </div>

      <div class="mb-6">
        <label for="gradeSystem" class="block text-gray-700 font-bold mb-2">Grade System:</label>
        <select v-model="gradeSystem" id="gradeSystem" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select grade system</option>
          <option value="A">A</option>
          <option value="B">B</option>
        </select>
        <p v-if="errors.grade_system" class="text-red-500 text-sm mt-1">{{ errors.grade_system.join(' ') }}</p>
      </div>

      <div class="mb-6">
        <label for="category" class="block text-gray-700 font-bold mb-2">Category:</label>
        <select v-model="category" id="category" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select subject category</option>
          <option value="compulsory">Compulsory</option>
          <option value="humanity">Humanity</option>
          <option value="other">Other</option>
        </select>
        <p v-if="errors.category" class="text-red-500 text-sm mt-1">{{ errors.category.join(' ') }}</p>
      </div>

      <!-- Submit or Update/Delete buttons -->
      <div class="flex justify-end space-x-4">
        <button type="submit" class="bg-blue-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-blue-600 transition-colors duration-300">
          {{ id ? 'Update Subject' : 'Create Subject' }}
        </button>
        <button v-if="id" @click.prevent="handleDelete" class="bg-red-500 hover:bg-red-700 text-white py-3 px-6 rounded-md focus:outline-none transition-colors duration-300">
          Delete Subject
        </button>
      </div>
    </form>
    <!-- Error Message -->
    <div v-if="error" class="text-red-500 mt-4">
      Error: {{ error }}
    </div>
  </div>
</template>

<script>
  import axios from '@/axios';
  import SpinnerComp from '@/components/SpinnerComp.vue';

  export default {
    components: {
      SpinnerComp,
    },
    props: {
      id: {
        type: [String, Number],
        default: null
      }
    },
    data() {
      return {
        subjectName: '',
        gradeSystem: '', // New field for grade system
        category: '',
        errors: {},
        loading: false,
        error:''
      };
    },
    computed: {
      isEditMode() {
        return !!this.id;
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(newId) {
          if (newId) {
            this.fetchSubject(newId);
          }
        }
      }
    },
    methods: {
      fetchSubject(id) {
        this.loading = true;
        axios.get(`/subjects/${id}`)
          .then(response => {
            this.subjectName = response.data.subject;
            this.gradeSystem = response.data.grade_system; // Update to fetch grade system
            this.category = response.data.category;
            this.errors = {};
          })
          .catch(error => {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      handleSubmit() {
        this.loading = true;
        const subjectData = {
          subject: this.subjectName,
          grade_system: this.gradeSystem, // Include grade system in the data payload
          category: this.category
        };

        const request = this.id ? 
          axios.post(`/subjects/${this.id}/update`, subjectData) : 
          axios.post('/subjects', subjectData);

        request.then(() => {
            const message = this.id ? 'Subject Successfully Updated' : 'Subject Successfully Created';
            alert(message);
            this.subjectName = '';
            this.gradeSystem = ''; // Clear grade system after submission
            this.category = '';
            this.errors = {};
            this.$emit('success');
          })
          .catch(error => {
            if (error.response && error.response.data.errors) {
              this.errors = error.response.data.errors;
            } else {
              const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
              this.error = errorMessage;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      handleDelete() {
        if (!confirm('Are you sure you want to delete this subject?')) {
          return;
        }
        this.loading = true;
        axios.post(`/subjects/${this.id}/delete`)
          .then(() => {
            alert('Subject Successfully Deleted');
            this.$emit('success');
          })
          .catch(error => {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

  
 
  