<template>
  <ModalLayout @close="closeModal">
    <div class="relative max-w-xl mx-auto p-6 bg-white rounded">
      <SpinnerComp :loading="loading" />
      <!-- Close Icon -->
      <button @click="closeModal" class="absolute top-0 right-0 mt-2 mr-2 text-gray-600 hover:text-red-600 focus:outline-none text-xl">
          <font-awesome-icon icon="times" />
        </button>
      <!-- Form for progression list approval -->
      <div v-if="progressionList.length">
        <h2 class="text-xl font-bold mb-4">Progression List for Approval</h2>
        <table class="min-w-full bg-white">
          <thead>
            <tr>
              <th class="py-2">Class ID</th>
              <th class="py-2">Class Name</th>
              <th class="py-2">Current Term</th>
              <th class="py-2">Next Term</th>
              <th class="py-2">Next Form</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in progressionList" :key="index">
              <td class="border px-4 py-2">{{ item.class_id }}</td>
              <td class="border px-4 py-2">{{ item.class_name }}</td>
              <td class="border px-4 py-2">
                Term {{ item.current_term.term_number }}
              </td>
              <td class="border px-4 py-2">
                {{ item.next_term.term_number === 'NA' ? 'NA' : `Term ${item.next_term.term_number}` }}
              </td>
              <td class="border px-4 py-2">{{ item.next_form }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>No progressions available for approval.</p>
      </div>
      <!-- Submit button -->
      <div class="flex justify-end mt-6" v-if="progressionList.length > 0">
        <button @click="approveProgressions" class="bg-blue-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none transition-colors duration-300">
          Approve
        </button>
      </div>
      <!-- Error Message -->
      <div v-if="error" class="text-red-500 mt-4">
        Error: {{ error }}
      </div>
    </div>
  </ModalLayout>
</template>

<script>
import axios from '@/axios';
import SpinnerComp from '@/components/SpinnerComp.vue';
import ModalLayout from '@/components/ModalLayout.vue';

export default {
  components: {
    SpinnerComp,
    ModalLayout,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    }
  },
  data() {
    return {
      progressionList: [],
      loading: false,
      error:''
    };
  },
  methods: {
    fetchProgressionList() {
      this.loading = true;
      axios.get('/progressions')
        .then(response => {
          this.progressionList = response.data;
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async approveProgressions() {
      this.loading = true;
      try {
        await axios.post('/progressions/approve');
      }catch(error) {
        const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
        this.error = errorMessage;
      }finally{
        this.loading = false;
      }
    },
    closeModal() {
      this.$emit('close');
    }
  },
  created() {
    this.fetchProgressionList();
  },
};
</script>
