<template>
  <ModalLayout>
    <div class="relative max-w-xl mx-auto p-4 bg-white rounded">
      <SpinnerComp :loading="loading" />

      <router-link to="/dashboard" class="mb-4 flex items-center hover:text-red-400 transition">
        <font-awesome-icon :icon="['fas', 'house']" class="mr-2" />
        Home
      </router-link>

      <form @submit.prevent="submitReport">
        <div class="mb-6">
          <label for="reportType" class="block text-gray-700 font-bold mb-2">Select Report/Sheet Type:</label>
          <select v-model="selectedReportType" id="reportType" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
            <option disabled value="">Select report or sheet type</option>
            <option value="form">Form Report</option>
            <option value="class-sheet">Class sheet</option>
            <option value="form-sheet">Form sheet</option>
          </select>
        </div>

        <div v-if="selectedReportType === 'form' || selectedReportType === 'form-sheet'" class="mb-6">
          <label for="form" class="block text-gray-700 font-bold mb-2">Select Form:</label>
          <select v-model="selectedForm" id="form" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
            <option disabled value="">Select a form</option>
            <option v-for="form in formList" :key="form.id" :value="form.id">{{ form.form }}</option>
          </select>
        </div>

        <div v-if="selectedReportType === 'class' || selectedReportType === 'class-sheet'" class="mb-6">
          <label for="class" class="block text-gray-700 font-bold mb-2">Select Class:</label>
          <ItemFilter
            :items="classList"
            label="Class"
            @item-selected="selectedClass = $event"
          />
        </div>

        <div v-if="selectedReportType === 'student'" class="mb-6">
          <label for="student" class="block text-gray-700 font-bold mb-2">Select Student:</label>
          <ItemFilter
            :items="studentList"
            label="Student"
            @item-selected="selectedStudent = $event"
          />
        </div>

        <div class="dates" v-if="isReportForm">
          <div class="mb-6">
            <label for="closeDate" class="block text-gray-700 font-bold mb-2">Close Date:</label>
            <input v-model="close_date" type="date" id="closeDate" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>
          <div class="mb-6">
            <label for="openDate" class="block text-gray-700 font-bold mb-2">Open Date:</label>
            <input v-model="open_date" type="date" id="openDate" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>
        </div>
        
        <div class="flex justify-end">
          <button type="submit" class="bg-red-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-red-600 transition-colors duration-300">Generate Report</button>
        </div>
      </form>

      <!-- Display Download Link -->
      <div v-if="downloadLink" class="mt-4">
        <a :href="downloadLink" target="_blank" class="block text-blue-500 hover:text-blue-700">Download Report</a>
      </div>

      <div v-if="error" class="text-red-500 mt-4">
        Error: {{ error }}
      </div>
    </div>
  </ModalLayout>
</template>
<script>
import axios from '@/axios';
import SpinnerComp from '@/components/SpinnerComp.vue';
import ModalLayout from '@/components/ModalLayout.vue';
import ItemFilter from '@/components/ItemFilter.vue';


export default {
  components: {
    SpinnerComp,
    ModalLayout,
    ItemFilter,
  },
  data() {
    return {
      loading: false,
      forms: [],
      classes: [],
      students: [],
      selectedReportType: '',
      selectedForm: '',
      selectedClass: null,
      selectedStudent: null,
      open_date: '',
      close_date: '',
      error: '',
      downloadLink: null,
      pdfTitle : '',
      studentsForPDF: []
    };
  },
  computed: {
    formList() {
      return Array.isArray(this.forms) ? this.forms.map(form => ({
        id: form.form,
        form: form.form,
      })) : [];
    },
    classList() {
      return Array.isArray(this.classes) ? this.classes.map(c => ({
        id: c.id,
        name: c.name,
      })) : [];
    },
    studentList() {
      return Array.isArray(this.students) ? this.students.map(s => ({
        id: s.id,
        name: `${s.id} - ${s.name}`,
      })) : [];
    },
    isReportForm() {
      return ['student','class','form'].includes(this.selectedReportType);
    }
  },
  methods: {
    fetchReportOptions() {
      if (this.selectedReportType === 'form' || this.selectedReportType === 'form-sheet') {
        this.fetchForms();
      } else if (this.selectedReportType === 'class' || this.selectedReportType === 'class-sheet') {
        this.fetchClasses();
      } else if (this.selectedReportType === 'student') {
        this.fetchStudents();
      }
    },
    fetchForms() {
      this.loading = true;
      axios.get('/forms')
        .then(response => {
        // Convert the data to an array format
        const data = Object.values(response.data);
        this.forms = data || [];
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }).finally(()=>{
          this.loading = false;
        });
    },
    fetchClasses() {
      this.loading = true;
      axios.get('/classes')
        .then(response => {
          this.classes = response.data || [];
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }).finally(()=>{
          this.loading = false;
        });
    },
    fetchStudents() {
      this.loading = true;
      axios.get('/students')
        .then(response => {
          this.students = response.data || [];
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }).finally(()=>{
          this.loading = false;
        });
    },
    async submitReport() {
      //Handle Sheets
      if(this.selectedReportType !== 'form') {
        //HANDLE SHEETS
        await this.generateSheets();
        return;
      }

      if (!this.selectedReportType || !this.open_date || !this.close_date) {
        this.error = 'Please fill in all the required fields.';
        return;
      }

      let reportOption = null;
      if (this.selectedReportType === 'form') {
        reportOption = this.selectedForm;
      } else if (this.selectedReportType === 'class') {
        reportOption = this.selectedClass;
      } else if (this.selectedReportType === 'student') {
        reportOption = this.selectedStudent;
      }

      if (!reportOption) {
        this.error = `Please select a ${this.selectedReportType}.`;
        return;
      }

      this.loading = true;
      const payload = {
        report_type: this.selectedReportType,
        report_option: reportOption,
        open_date: this.open_date,
        close_date: this.close_date,
      };

      try {
        // Make the request to generate the report and download it
        const response = await axios({
          url: '/generate-report',
          method: 'POST',
          data: payload,
          responseType: 'blob', // Expecting a binary response
        });

        // Create a URL for the ZIP Blob
        const fileUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
        const link = document.createElement('a');
        link.href = fileUrl;

        // Set the name for the download file
        link.setAttribute('download', `Form ${this.selectedForm} reports.zip`);
        
        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(fileUrl);

      } catch (error) {
        const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
        this.error = errorMessage;
      } finally {
        this.loading = false;
      }
    },
    async generateSheets() {
      const sheetType = this.selectedReportType === 'class-sheet' ? 'class' : 'form';
      this.loading = true;
      
      try {
        const payload = {
          'sheetType': sheetType,
        };

        if (sheetType === 'form') {
          payload['form'] = this.selectedForm;
        } else {
          payload['class_id'] = this.selectedClass;
        }

        // Make the request to generate the sheet
        const response = await axios({
          url: '/sheets',
          method: 'POST',
          data: payload,
          responseType: 'blob', // Expecting a binary response
        });

        // Create a URL for the PDF Blob
        const fileUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = fileUrl;

        let sheetFileName = '';
        if (sheetType === 'class') {
          const classRecord = this.classes.find((c) => c.id == this.selectedClass);
          sheetFileName = `${classRecord.name} Sheet.pdf`;
        } else if (sheetType === 'form') {
          sheetFileName = `Form ${this.selectedForm} Sheet.pdf`;
        }

        link.setAttribute('download', sheetFileName);
        
        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(fileUrl);
        
      } catch (error) {
        const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
        this.error = errorMessage;
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.selectedReportType = '';
      this.selectedForm = '';
      this.selectedClass = null;
      this.selectedStudent = null;
      this.open_date = '';
      this.close_date = '';
      this.error = '';
      this.downloadLink = null;
    },
  },
  watch: {
    selectedReportType() {
      this.fetchReportOptions();
    }
  }
};
</script>

<style scoped>
#sheet-template {
    font-family: sans-serif;
}

#sheet-template table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

#sheet-template th,
#sheet-template td {
    padding: 8px;
    text-align: left;
    border: 1px solid #000;
}

#sheet-template th {
    background-color: #f2f2f2;
    padding: 4px;
}

#sheet-template td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#sheet-template .name-column {
    width: 30%; /* Adjust width for the name column */
}

</style>
