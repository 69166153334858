<template>
  <ModalLayout @close="closeModal">
    <SpinnerComp :loading="loading" />
    <div class="relative max-w-3xl mx-auto bg-white rounded">
      <!-- Close Icon -->
      <button @click="closeModal" class="absolute top-0 
      right-0 mt-2 mr-2 text-gray-600 hover:text-red-600 focus:outline-none text-xl">
        <font-awesome-icon icon="times" />
      </button>
      <!-- Dynamic Form Header -->
      <h2 class="text-2xl text-center font-bold mb-6">{{ getFormTitle() }}</h2>
      
      <!-- Dynamic Form -->
      <component :is="getActiveFormComponent()" :activeType="activeType" :id="id" @close="closeModal" />
    </div>
  </ModalLayout>
</template>

<script>
import ModalLayout from '../ModalLayout.vue';
import SpinnerComp from '../SpinnerComp.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import CombinedForm from './CombinedForm.vue';
import InviteForm from './InviteForm.vue';

export default {
  components: {
    ModalLayout,
    SpinnerComp,
    'font-awesome-icon': FontAwesomeIcon,
    CombinedForm,
    InviteForm,
  },
  props: {
    activeType : {
      type : String,
      required : true
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return { 
      loading: false,
    };
  },
  methods: {
    getActiveFormComponent() {
      switch (this.activeType) {
        case 'students':
          return 'CombinedForm';
        case 'teachers':
        case 'staff':
          return 'InviteForm';
        default:
          return 'div';
      }
    },
    getFormTitle() {
      switch (this.activeType) {
        case 'students':
          return 'Register Student';
        case 'teachers':
          return 'Invite Teacher';
        case 'staff':
          return 'Invite Staff';
        default:
          return '';
      }
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
