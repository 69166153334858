import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUsers, faGraduationCap, faCogs, faCalendarAlt, 
    faStar, faPoll, faSortUp, faSortDown,faTimes,
    faChartSimple,faHouse } from '@fortawesome/free-solid-svg-icons';

// Add icons to the library
library.add(faUsers, faGraduationCap, faCogs, faCalendarAlt, faStar, 
    faPoll, faSortUp, faSortDown,faTimes,faChartSimple,faHouse);

// Register the FontAwesomeIcon component globally
export { FontAwesomeIcon };
