<template>
  <LayoutComp>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4 mb-6 z-0">
      <DashboardCard 
        :icon="['fas', 'users']" 
        title="Community" 
        description="Manage school community members: teachers, parents, students, and staff."
        route="Community" />
      <DashboardCard 
        :icon="['fas', 'graduation-cap']" 
        title="Academics" 
        description="Manage subjects and subject enrollment."
        route="Academic" />
      <DashboardCard 
        :icon="['fas', 'cogs']" 
        title="Administration" 
        description="Manage roles, departments, and administrative settings."
        route="Admin" />
      <DashboardCard 
        :icon="['fas', 'calendar-alt']" 
        title="Scheduling" 
        description="Plan academic years, terms, and class schedules."
        route="Schedule" />
      <DashboardCard 
        :icon="['fas', 'star']" 
        title="Extracurricular" 
        description="Organize student houses and enrollments"
        route="House" />
      <DashboardCard 
        :icon="['fas', 'poll']" 
        title="Exams & Assessments" 
        description="Manage exam activities and results"
        route="Exam" />
      <DashboardCard 
        :icon="['fas', 'chart-simple']" 
        title="Report & Sheets" 
        description="Generate Report Forms, Class or Form Sheets"
        route="Report" />
      </div>
  </LayoutComp>
</template>

<script>
import LayoutComp from '@/components/LayoutComp.vue';
import DashboardCard from '@/components/DashboardCard.vue';

export default {
  name: 'DashboardPage',
  components: {
    LayoutComp,
    DashboardCard,
  },
};
</script>

