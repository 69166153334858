<template>
  <LayoutComp>
    <SpinnerComp :loading="loading" />
    <div class="p-10 max-w-lg mx-auto mb-34 bg-white rounded-lg shadow-lg">
      <header class="text-center">
        <h2 class="text-3xl font-bold mb-4">Login</h2>
        <p class="mb-6">Login to your account</p>
      </header>

      <form @submit.prevent="loginUser">
        <!-- Error Message -->
        <p v-if="errors.message" class="text-red-500 text-center text-sm mb-4">{{ errors.message }}</p>
        
        <div class="mb-6">
          <label for="email" class="block text-gray-700 font-bold mb-2">Email</label>
          <input
            type="email"
            id="email"
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
            v-model="form.email"
          />
          <p v-if="errors.email" class="text-red-500 text-sm mt-1">{{ errors.email.join(' ') }}</p>
        </div>

        <div class="mb-6">
          <label for="password" class="block text-gray-700 font-bold mb-2">Password</label>
          <input
            type="password"
            id="password"
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
            v-model="form.password"
          />
          <p v-if="errors.password" class="text-red-500 text-sm mt-1">{{ errors.password.join(' ') }}</p>
        </div>

        <div class="mb-6">
          <button type="submit" class="bg-laravel text-white py-3 px-6 rounded-md hover:bg-black focus:outline-none focus:bg-black transition-colors duration-300 w-full">Login</button>
        </div>
      </form>
      <!-- Router Links for Forgot Password and Register -->
      <div class="mt-2 text-center">
        <p class="text-sm text-gray-500 mt-4">
          Forgot Passowrd?
          <router-link
            to="/request-password-reset"
            class="text-blue-500 hover:text-blue-700"
          >
            Reset
          </router-link>
        </p>
      </div>
    </div>
  </LayoutComp>
</template>

<script>
import axiosInstance from '../../axios'; // Your axios instance with the '/api' prefix
import axios from 'axios'; // Plain axios for the CSRF token request
import LayoutComp from '@/components/LayoutComp.vue';
import SpinnerComp from '@/components/SpinnerComp.vue';

export default {
  name: 'LoginPage',
  components: { LayoutComp, SpinnerComp },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      errors: {},
      loading: false
    };
  },
  methods: {
    loginUser() {
      this.loading = true;
      this.errors = {};
      // Fetch CSRF token using plain axios
      axios.get(process.env.VUE_APP_CSRF_URL, {
        withCredentials: true
      })
      .then(() => {
        // Now make the login request using your axios instance
        return axiosInstance.post('/users/login', this.form);
      })
      .then(response => {
        // Handle successful login
        alert('Login successful');
        const token = response.data.token;
        const userType = response.data.user_type;
        const name = response.data.name;
        console.log(response.data);
        // Save the token and user type in localStorage or Vuex store for future API requests
        localStorage.setItem('token', token);
        localStorage.setItem('user_type', userType);
        localStorage.setItem('name',name);
        // Redirect to dashboard after successful login
        this.$router.push('/dashboard');
      })
      .catch(error => {
        // Handle validation errors or 401 unauthorized error
        if (error.response && error.response.status === 401) {
          this.errors = { message: 'Invalid credentials. Please check your email and password.' };
        } else if (error.response && error.response.data.errors) {
          this.errors = error.response.data.errors;
        } else {
          this.errors = { message: 'Something went wrong. Please try again later.' };
        }
      })
      .finally(() => {
        this.loading = false;
      });
    }

  }
};
</script>
