<template>
    <ModalLayout @close="closeModal">
      <div class="relative max-w-3xl mx-auto bg-white rounded">
        <!-- Close Icon -->
        <button @click="closeModal" class="absolute top-0 right-0 mt-2 mr-2 text-gray-600 hover:text-red-600 focus:outline-none text-xl">
          <font-awesome-icon icon="times" />
        </button>
        <!-- Dynamic Form Header -->
        <h2 class="text-2xl text-center font-bold mb-6">{{ getFormTitle() }}</h2>
  
        <!-- Dynamic Form -->
        <component :is="getActiveFormComponent()" :id="id" @close="closeModal" @success="handleSuccess" />
  
        <!-- Placeholder for Development -->
        <p v-if="!getActiveFormComponent()" class="text-center text-gray-600 mt-8">Form component development in progress...</p>
      </div>
    </ModalLayout>
  </template>
  
  <script>
  import ModalLayout from '../ModalLayout.vue';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import SubjectForm from './SubjectForm.vue';
  import SubjectEnrollmentForm from './SubjectEnrollmentForm.vue';


  export default {
    components: {
      ModalLayout,
      'font-awesome-icon': FontAwesomeIcon,
      SubjectForm,
      SubjectEnrollmentForm
    },
    props: {
      activeType: {
        type: String,
        required: true
      },
      id: {
        type: [String, Number],
        default: null
      }
    },
    methods: {
      getActiveFormComponent() {
        switch (this.activeType) {
          case 'subjects':
            return 'SubjectForm';
          case 'subject enrollments':
            return 'SubjectEnrollmentForm';
          default:
            return null;
        }
      },
      getFormTitle() {
        switch (this.activeType) {
          case 'subjects':
            return this.id ? 'Edit Subject' : 'Create New Subject';
          case 'subject enrollments':
            return this.id ? 'Edit Subject Enrollment' : 'Enroll Students to Subject'
          default:
            return '';
        }
      },
      closeModal() {
        this.$emit('close');
      },
      handleSuccess() {
        this.$emit('success');
        this.closeModal();
      }
    }
  };
  </script>
  