import { createRouter, createWebHistory } from 'vue-router';
import RegisterPage from '../views/users/RegisterPage.vue';
import LoginPage from '../views/users/LoginPage.vue';
import DashboardPage from '@/views/DashboardPage.vue';
import CommunityPage from '@/views/community/CommunityPage.vue';
import SchedulePage from '@/views/SchedulePage.vue';
import HousePage from '@/views/HousePage.vue';
import AdminPage from '@/views/AdminPage.vue';
import AcademicPage from '@/views/AcademicPage.vue';
import ExamPage from '@/views/ExamPage.vue';
import ReportForm from '@/components/forms/ReportForm.vue';
import RequestPasswordReset from '@/views/users/RequestPasswordReset.vue';
import PasswordReset from '@/views/users/PasswordReset.vue';

const routes = [
  {
    path: '/',
    redirect: {name:'Login'}
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage
  },
  {
    path: '/dashboard/community',
    name: 'Community',
    component: CommunityPage
  },
  {
    path: '/dashboard/schedule',
    name: 'Schedule',
    component: SchedulePage
  },
  {
    path: '/dashboard/house',
    name: 'House',
    component: HousePage
  },
  {
    path: '/dashboard/admin',
    name: 'Admin',
    component: AdminPage
  },
  {
    path: '/dashboard/academics',
    name: 'Academic',
    component: AcademicPage
  },
  {
    path: '/dashboard/exams',
    name: 'Exam',
    component: ExamPage
  },
  {
    path: '/report',
    name: 'Report',
    component: ReportForm
  },
  {
    path: '/request-password-reset',
    name: 'RequestReset',
    component: RequestPasswordReset
  },
  {
    path: '/reset-password/',
    name: 'PasswordReset',
    component: PasswordReset
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token'); // Check if user is authenticated
  const protectedRoutes = ['Dashboard', 'Community', 'Schedule',
                           'House', 'Admin', 'Academic', 'Exam','Report']; // Routes that require authentication
  
  if (protectedRoutes.includes(to.name) && !token) {
    // If the route requires auth and user is not authenticated, redirect to login
    next({ name: 'Login' });
  } else {
    next(); // Otherwise, proceed to the route
  }
});

export default router;
