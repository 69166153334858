<template>
  <div class="max-w-xl mx-auto p-6 bg-white rounded">
    <SpinnerComp :loading="loading" />

    <form @submit.prevent="submitResults">
      <div class="mb-6">
        <label for="examType" class="block text-gray-700 font-bold mb-2">Select Exam:</label>
        <select v-model="selectedExamType" id="examType" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select an exam</option>
          <option v-for="exam in examTypes" :key="exam.id" :value="exam.id">{{ exam.type }}</option>
        </select>
      </div>

      <div class="mb-6">
        <label for="student" class="block text-gray-700 font-bold mb-2">Select Class:</label>
        <select v-model="selectedClass" id="class" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select a class</option>
          <option v-for="c in classes" :key="c.id" :value="c.id">{{ c.name }}</option>
        </select>
      </div>

      <div class="mb-6">
        <label for="subject" class="block text-gray-700 font-bold mb-2">Select Subject:</label>
        <select v-model="selectedSubject" id="subject" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select a subject</option>
          <option v-for="subject in subjects" :key="subject.id" :value="subject.id">{{ subject.subject }}</option>
        </select>
      </div>

      <div class="mb-6">
        <label for="student" class="block text-gray-700 font-bold mb-2">Select Student:</label>
        <select v-model="selectedStudent" id="student" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select a student</option>
          <option v-for="(student, index) in students" :key="student.id" :value="student.id">
            {{ index + 1 }} - {{ student.first_name }} {{ student.middle_name ? student.middle_name : '' }} {{ student.last_name }}
          </option>
        </select>
      </div>

      <div class="mb-6">
        <label for="marks" class="block text-gray-700 font-bold mb-2">Enter Marks:</label>
        <input type="number" v-model="marks" id="marks" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600" />
      </div>

      <div class="mb-6">
        <button @click.prevent="addResult" class="py-2 px-4 bg-blue-500 text-white rounded-lg mt-2 focus:outline-none hover:bg-blue-600">Add Result</button>
      </div>

      <div class="mb-6">
        <label v-if="results.length > 0" class="block text-gray-700 font-bold mb-2">Added Results:</label>
        <p v-if="results.length === 0" class="text-gray-500">No results added.</p>
        <ul>
          <li v-for="result in results" :key="result.student.id">
            {{ result.student.id }} - {{ result.student.first_name }} {{ result.student.last_name }}: {{ result.marks }} marks
            <button @click.prevent="removeResult(result.student.id)" class="ml-2 text-red-500">Remove</button>
          </li>
        </ul>
      </div>

      <div class="flex justify-end">
        <button type="submit" class="bg-red-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-red-600 transition-colors duration-300">Submit Results</button>
      </div>
    </form>

    <div v-if="error" class="text-red-500 mt-4">
      Error: {{ error }}
    </div>
  </div>
</template>

<script>
import axios from '@/axios';
import SpinnerComp from '@/components/SpinnerComp.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    SpinnerComp
  },
  data() {
    return {
      loading: false,
      classes : [],
      students: [],
      selectedStudent: null,
      selectedClass : null,
      examTypes: [],
      selectedExamType: null,
      subjects: [],
      selectedSubject: null,
      marks: null,
      results: [],
      error: '',
    };
  },
  computed : {
    studentList() {
      return Array.isArray(this.students) ? this.students.map((student) => {
        return {
          'id' : student.id,
          'name' : `${student.first_name} ${student.middle_name} ${student.last_name}`
        }
      }) : [];
    }
  },
  mounted() {
    this.fetchExamTypes();
    this.fetchSubjects();
    this.fetchClasses();
  },
  watch: {
    selectedClass() {
      this.fetchStudentsInClass();
    }
  },
  methods: {
    ...mapActions('exams',['fetchRecords']),
    fetchExamTypes() {
      this.loading = true;
      axios.get('schedule/exam types/all')
        .then(response => {
          this.examTypes = response.data || [];
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }).finally(() => {
          this.loading = false;
        });
    },
    fetchSubjects() {
      this.loading = true;
      axios.get('/subjects/all')
        .then(response => {
          this.subjects = response.data || [];
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }).finally(() => {
          this.loading = false;
        });
    },
    fetchClasses() {
      this.loading = true;
      axios.get('schedule/classes/enrolled')
      .then(response=> {
        this.classes = response.data || [];
      })
      .catch(error=> {
        const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
        this.error = errorMessage;
      }).finally(() => {
        this.loading = false;
      });
    },
    fetchStudentsInClass() {
      this.loading = true;
      axios.get(`/students-in-class/${this.selectedClass}`)
      .then(response => {
        this.students = response.data || [];
      })
      .catch(error => {
        const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
        this.error = errorMessage;
      }).finally(() => {
        this.loading = false;
      });
    },
    addResult() {
      if (!this.selectedStudent || this.marks === null || this.marks === '') {
        this.error = 'Please select a student and enter marks.';
        return;
      }
      const student = this.students.find(student => student.id === this.selectedStudent);
      if (student) {
        this.results.push({ student, marks: this.marks });
        this.selectedStudent = null;
        this.marks = null;
      }
    },
    removeResult(studentId) {
      this.results = this.results.filter(result => result.student.id !== studentId);
    },
    submitResults() {
      if (!this.results.length) {
        this.error = 'Please add at least one result.';
        return;
      }
      if (!this.selectedExamType || !this.selectedSubject) {
        this.error = 'Please select an exam and a subject.';
        return;
      }

      this.loading = true;
      const payload = {
        exam_type_id: this.selectedExamType,
        subject_id: this.selectedSubject,
        results: this.results.map(result => ({
          student_id: result.student.id,
          marks: result.marks,
        })),
      };
      axios.post('/schedule/results', payload)
        .then(() => {
          alert('Results submitted successfully!');
          this.fetchRecords();
          this.resetForm();
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      this.selectedExamDone = null;
      this.selectedSubject = null;
      //this.selectedStudent = null;
      this.marks = null;
      this.results = [];
      this.error = null;
    },
  },
};
</script>
