<template>
  <div class="relative max-w-xl mx-auto p-6 bg-white rounded">
    <SpinnerComp :loading="loading" />

    <form @submit.prevent="handleSubmit">
      <!-- Role Selection -->
      <div class="mb-6">
        <label for="role" class="block text-gray-700 font-bold mb-2">Role:</label>
        <select v-model="selectedRoleId" @change="fetchAssignableOptions" id="role" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select a role</option>
          <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.role }}</option>
        </select>
        <p v-if="errors.role_id" class="text-red-500 text-sm mt-1">{{ errors.role_id.join(' ') }}</p>
      </div>

      <!-- Person Selection -->
      <div class="mb-6" v-if="assignableOptions.length > 0">
        <label for="person" class="block text-gray-700 font-bold mb-2">Person:</label>
        <select v-model="selectedPersonId" id="person" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select a person</option>
          <option v-for="option in assignableOptions" :key="option.id" :value="option.id">{{ concatenateFields(option) }}</option>
        </select>
        <p v-if="errors.person_id" class="text-red-500 text-sm mt-1">{{ errors.person_id.join(' ') }}</p>
      </div>

      <!-- Context Selection -->
      <div class="mb-6" v-if="contextOptions.length > 0">
        <label for="context" class="block text-gray-700 font-bold mb-2">Area:</label>
        <select v-model="selectedContextId" id="context" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select an area</option>
          <option v-for="context in contextOptions" :key="context.id" :value="context.id">{{ concatenateFields(context) }}</option>
        </select>
        <p v-if="errors.context_id" class="text-red-500 text-sm mt-1">{{ errors.context_id.join(' ') }}</p>
      </div>

      <!-- Subject Selection for 'Subject Teacher' -->
      <div class="mb-6" v-if="selectedRoleId && isSubjectTeacherRole(selectedRoleId)">
        <label for="subject" class="block text-gray-700 font-bold mb-2">Subject:</label>
        <select v-model="selectedSubjectId" id="subject" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select a subject</option>
          <option v-for="subject in subjects" :key="subject.id" :value="subject.id">{{ subject.subject }}</option>
        </select>
        <p v-if="errors.subject_id" class="text-red-500 text-sm mt-1">{{ errors.subject_id.join(' ') }}</p>
      </div>

      <div v-if="errors.message" class="mb-4 text-red-600">
        {{ errors.message }}
      </div>

      <div class="flex justify-end space-x-4">
        <button type="submit" class="bg-blue-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-blue-600 transition-colors duration-300">
          {{ id ? 'Update Assignment' : 'Create Assignment' }}
        </button>
        <button v-if="id" @click.prevent="handleDelete" class="bg-red-500 hover:bg-red-700 text-white py-3 px-6 rounded-md focus:outline-none transition-colors duration-300">
          Delete Assignment
        </button>
      </div>
    </form>
    <!-- Error Message -->
    <div v-if="error" class="text-red-500 mt-4">
      Error: {{ error }}
    </div>
  </div>
</template>

<script>
import axios from '@/axios';
import SpinnerComp from '@/components/SpinnerComp.vue';

export default {
  components: {
    SpinnerComp,
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      selectedRoleId: '',
      selectedPersonId: '',
      selectedContextId: '',
      selectedSubjectId: '', // Added for subject selection
      roles: [],
      assignableOptions: [],
      contextOptions: [],
      subjects: [], // Added for subject selection
      errors: {},
      loading: false,
      error:''
    };
  },
  computed: {
    isEditMode() {
      return !!this.id;
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.fetchRoleAssignment(newId);
        }
      }
    },
    selectedRoleId(newRoleId) {
      if (this.isSubjectTeacherRole(newRoleId)) {
        this.fetchSubjects();
      }
    }
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    fetchRoles() {
      this.loading = true;
      axios.get('schedule/roles/all')
        .then(response => {
          this.roles = response.data;
        })
        .catch(error => {
          console.error(error);
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }).finally(()=>{
          this.loading = false;
        });
    },
    fetchRoleAssignment(id) {
      this.loading = true;
      axios.get(`schedule/role-assignments/${id}`)
        .then(response => {
          const assignment = response.data;
          this.selectedRoleId = assignment.role_id;
          this.selectedPersonId = assignment.person_id;
          this.selectedContextId = assignment.context_id;
          this.selectedSubjectId = assignment.subject_id; // Assign subject_id if available
          this.errors = {};
          this.fetchAssignableOptions();
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchAssignableOptions() {
      this.assignableOptions = [];
      this.contextOptions = [];
      if (!this.selectedRoleId) return;

      const selectedRole = this.roles.find(role => role.id === this.selectedRoleId);
      if (selectedRole) {
        this.fetchPersons(selectedRole.assignable_to);
        this.fetchContexts(selectedRole.jurisdiction);
        if (this.isSubjectTeacherRole(this.selectedRoleId)) {
          this.fetchSubjects();
        }
      }
    },
    fetchPersons(assignableTo) {
      let endpoint = '';
      switch (assignableTo) {
        case 'teacher':
          endpoint = '/all-teachers';
          break;
        case 'student':
          endpoint = 'students';
          break;
        case 'staff':
          endpoint = '/all-staff';
          break;
        case 'parent':
          endpoint = 'parents';
          break; 
      }
      this.loading = true;
      axios.get(endpoint)
        .then(response => {
          this.assignableOptions = response.data;
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }).finally(()=>{
          this.loading = false;
        });
    },
    fetchContexts(jurisdiction) {
      let endpoint = '';
      switch (jurisdiction) {
        case 'class':
          endpoint = 'schedule/classes/enrolled';
          break;
        case 'department':
          endpoint = 'departments';
          break;
        case 'house':
          endpoint = 'schedule/houses/all';
          break;
        case 'school':
          this.contextOptions = [{ id: 'school', name: 'Entire School' }];
          return;
      }
      this.loading = true;
      axios.get(endpoint)
        .then(response => {
          this.contextOptions = response.data;
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }).finally(()=>{
          this.loading = false;
        });
    },
    fetchSubjects() {
      this.loading = true;
      axios.get('/subjects/all')
        .then(response => {
          this.subjects = response.data;
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }).finally(()=>{
          this.loading = false;
        });
    },
    concatenateFields(object) {
      return Object.entries(object)
        .filter(([key]) => key !== 'id')
        .map(([, value]) => value)
        .join(' ');
    },
    handleSubmit() {
      this.loading = true;
      const assignmentData = {
        role_id: this.selectedRoleId,
        person_id: this.selectedPersonId,
        context_id: this.selectedContextId !== 'school' ? this.selectedContextId : null,
        subject_id: this.selectedSubjectId, // Include subject_id in assignment data
      };

      // Adjust based on your API endpoint
      let endpoint = 'schedule/role-assignments';
      let method = 'post';

      if (this.isEditMode) {
        endpoint += `/${this.id}`;
        method = 'post';
      }

      axios[method](endpoint, assignmentData)
        .then(response => {
          console.log('Role assignment saved successfully:', response.data);
          // Optionally show success message or redirect
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDelete() {
      // Implement deletion logic
    },
    isSubjectTeacherRole(roleId) {
      const role = this.roles.find(role => role.id === roleId);
      return role && role.role === 'Subject Teacher';
    }
  }
};
</script>


