<template>
  <div class="relative max-w-xl mx-auto p-6 bg-white rounded">
    <SpinnerComp :loading="loading" />

    <!-- Form for creating or editing an exam type -->
    <form @submit.prevent="handleSubmit">
      <div class="mb-6">
        <label for="examType" class="block text-gray-700 font-bold mb-2">Exam Type:</label>
        <input 
          type="text" 
          v-model="examType" 
          id="examType" 
          class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
        <p v-if="errors.type" class="text-red-500 text-sm mt-1">{{ errors.type.join(' ') }}</p>
      </div>

      <div class="mb-6">
        <label for="frequency" class="block text-gray-700 font-bold mb-2">Frequency:</label>
        <select 
          v-model="frequency" 
          id="frequency" 
          class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
        >
          <option value="">Select Frequency</option>
          <option value="termly">Termly</option>
          <option value="arbitrary">Arbitrary</option>
        </select>
        <p v-if="errors.frequency" class="text-red-500 text-sm mt-1">{{ errors.frequency.join(' ') }}</p>
      </div>

      <div class="mb-6">
        <label for="inReport" class="block text-gray-700 font-bold mb-2">In Report:</label>
        <select 
          v-model="inReport" 
          id="inReport" 
          class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
        >
          <option value="">Select Option</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
        <p v-if="errors.in_report" class="text-red-500 text-sm mt-1">{{ errors.in_report.join(' ') }}</p>
      </div>

      <!-- Error message display -->
      <div v-if="errors.message" class="mb-4 text-red-600">
        {{ errors.message }}
      </div>

      <!-- Submit or Update/Delete buttons -->
      <div class="flex justify-end space-x-4">
        <button type="submit" class="bg-blue-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-blue-600 transition-colors duration-300">
          {{ id ? 'Update Exam Type' : 'Create Exam Type' }}
        </button>
        <button v-if="id" @click.prevent="handleDelete" class="bg-red-500 hover:bg-red-700 text-white py-3 px-6 rounded-md focus:outline-none transition-colors duration-300">
          Delete Exam Type
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from '@/axios';
import SpinnerComp from '@/components/SpinnerComp.vue';

export default {
  components: {
    SpinnerComp,
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      examType: '',
      frequency: '',
      inReport: '',
      errors: {},
      loading: false,
    };
  },
  computed: {
    isEditMode() {
      return !!this.id;
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.fetchExamType(newId);
        }
      }
    }
  },
  methods: {
    fetchExamType(id) {
      this.loading = true;
      axios.get(`schedule/exam types/${id}`)
        .then(response => {
          this.examType = response.data.type;
          this.frequency = response.data.frequency;
          this.inReport = response.data.in_report;
          this.errors = {};
        })
        .catch(error => {
          console.error('Error fetching exam type:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSubmit() {
      this.loading = true;
      const examTypeData = {
        type: this.examType,
        frequency: this.frequency,
        in_report: this.inReport,
      };

      const request = this.id ? 
        axios.post(`schedule/exam types/${this.id}/update`, examTypeData) : 
        axios.post('schedule/exam types', examTypeData);

      request.then(() => {
          const message = this.id ? 'Exam Type Successfully Updated' : 'Exam Type Successfully Created';
          alert(message);
          this.examType = '';
          this.frequency = '';
          this.inReport = '';
          this.errors = {};
          this.$emit('success');
        })
        .catch(error => {
          if (error.response && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            console.error(error);
            this.errors = { message: 'Something went wrong. Please try again later.' };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDelete() {
      if (!confirm('Are you sure you want to delete this exam type?')) {
        return;
      }
      this.loading = true;
      axios.post(`schedule/exam types/${this.id}/delete`)
        .then(() => {
          alert('Exam Type Successfully Deleted');
          this.$emit('success');
        })
        .catch(error => {
          console.error('Error deleting exam type:', error);
          this.errors = { message: 'Failed to delete exam type. Please try again later.' };
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
