<template>
  <div class="max-w-md mx-auto p-6 bg-white rounded shadow-md">
    <SpinnerComp :loading="loading" />
    <form @submit.prevent="submitForm" class="space-y-4">
      <!-- Error Message -->
      <p v-if="errors.message" class="text-red-500 text-center text-sm mb-4">{{ errors.message }}</p>

      <!-- Class Selection -->
      <div>
        <label for="class" class="block text-gray-700 font-bold mb-2">Class</label>
        <select v-model="form.class_id" class="block w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option v-for="classItem in classes" :key="classItem.id" :value="classItem.id">
            {{ classItem.name }}
          </option>
        </select>
        <p v-if="errors.class_id" class="text-red-500 text-sm mt-1">{{ errors.class_id.join(' ') }}</p>
      </div>

      <!-- Role Selection -->
      <div>
        <label for="role" class="block text-gray-700 font-bold mb-2">Role</label>
        <select v-model="form.class_role_id" @change="handleRoleChange" class="block w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option value="2">Class Teacher</option>
          <option value="3">Subject Teacher</option>
        </select>
        <p v-if="errors.class_role_id" class="text-red-500 text-sm mt-1">{{ errors.class_role_id.join(' ') }}</p>
      </div>

      <!-- Subject Selection (conditionally shown) -->
      <div v-if="showSubjectSelection">
        <label for="subject" class="block text-gray-700 font-bold mb-2">Subject</label>
        <select v-model="form.subject_id" class="block w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option v-for="subject in subjects" :key="subject.id" :value="subject.id">
            {{ subject.subject }}
          </option>
        </select>
        <p v-if="errors.subject_id" class="text-red-500 text-sm mt-1">{{ errors.subject_id.join(' ') }}</p>
      </div>

      <!-- Teacher Selection -->
      <div>
        <label for="teacher" class="block text-gray-700 font-bold mb-2">Teacher</label>
        <select v-model="form.teacher_id" class="block w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option v-for="teacher in teachers" :key="teacher.id" :value="teacher.id">
            {{ teacher.name }}
          </option>
        </select>
        <p v-if="errors.teacher_id" class="text-red-500 text-sm mt-1">{{ errors.teacher_id.join(' ') }}</p>
      </div>

      <button type="submit" class="block w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:bg-blue-600 transition duration-300">
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import axios from '@/axios';
import SpinnerComp from '@/components/SpinnerComp.vue';

export default {
  components: {
    SpinnerComp
  },
  data() {
    return {
      form: {
        class_id: '',
        class_role_id: '',
        subject_id: '',
        teacher_id: ''
      },
      classes: [],
      subjects: [],
      teachers: [],
      showSubjectSelection: false,
      errors: {},
      loading: false
    };
  },
  mounted() {
    this.fetchClasses();
    this.fetchSubjects();
    this.fetchTeachers();
  },
  methods: {
    fetchClasses() {
      axios.get('/schedule/classes/enrolled')
        .then(response => {
          this.classes = response.data;
        })
        .catch(error => {
          console.error('Error fetching classes:', error);
        });
    },
    fetchSubjects() {
      axios.get('/schedule/subjects/all')
        .then(response => {
          this.subjects = response.data;
        })
        .catch(error => {
          console.error('Error fetching subjects:', error);
        });
    },
    fetchTeachers() { 
      
      axios.get('/all-teachers')
        .then(response => {
          this.teachers = response.data;
          console.log(response.data);
        })
        .catch(error => {
          console.error('Error fetching teachers:', error);
        });
    },
    handleRoleChange() {
      this.showSubjectSelection = this.form.class_role_id === '3';
    },
    submitForm() {
      this.loading = true;
      axios.post('/schedule/class roles', this.form)
        .then(response => {
          alert('Class role assigned successfully');
          console.log(response.data);
          // Reset form or handle success response
          this.form = {
            class_id: '',
            class_role_id: '',
            subject_id: '',
            teacher_id: ''
          };
          this.errors = {};
        })
        .catch(error => {
          if (error.response && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            this.errors = { message: 'Something went wrong. Please try again later.' };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
