import axios from '../../axios';

const state = {
  records: [],
  activeType: 'houses',
  searchTerm: '',
  sortField: '',
  sortDirection: '',
  currentPage: 1,
  lastPage: 1,
  total: 0,
  loading: false,
  error: null, // Added error handling
};

const mutations = {
  SET_RECORDS(state, data) {
    state.records = data.data;
    state.currentPage = data.current_page;
    state.lastPage = data.last_page;
    state.total = data.total;
  },
  SET_ACTIVE_TYPE(state, type) {
    state.activeType = type;
  },
  SET_SEARCH_TERM(state, term) {
    state.searchTerm = term;
  },
  SET_SORT(state, { field, direction }) {
    state.sortField = field;
    state.sortDirection = direction;
  },
  SET_PAGE(state, page) {
    state.currentPage = page;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_LOADING(state,loading) {
    state.loading = loading;
  }
};

const actions = {
  async fetchRecords({ commit, state }) {
    console.log('Fetcing House');
    try {
      commit('SET_LOADING',true);
      const response = await axios.get(`/schedule/${state.activeType}`, {
        params: {
          search: state.searchTerm,
          sortField: state.sortField,
          sortDirection: state.sortDirection,
          page: state.currentPage,
        },
      });
      commit('SET_RECORDS', response.data);
      commit('SET_LOADING',false);
      console.log(response.data);
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
      commit('SET_ERROR', errorMessage); // Handle error
    }finally{
      commit('SET_LOADING',false);
    }
  },
  setActiveType({ commit, dispatch }, type) {
    commit('SET_ACTIVE_TYPE', type);
    dispatch('fetchRecords');
  },
  setSearchTerm({ commit, dispatch }, term) {
    commit('SET_SEARCH_TERM', term);
    dispatch('fetchRecords');
  },
  setSort({ commit, dispatch }, { field, direction }) {
    commit('SET_SORT', { field, direction });
    dispatch('fetchRecords');
  },
  setPage({ commit, dispatch }, page) {
    commit('SET_PAGE', page);
    dispatch('fetchRecords');
  },
  setLoading({ commit },loading) {
    commit('SET_LOADING',loading);
  }
};

const getters = {
  records: (state) => state.records,
  activeType: (state) => state.activeType,
  sortField: (state) => state.sortField,
  sortDirection: (state) => state.sortDirection,
  currentPage: (state) => state.currentPage,
  lastPage: (state) => state.lastPage,
  total: (state) => state.total,
  error: (state) => state.error, // Getter for error
  loading: (state)  => state.loading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
