<template>
    <div class="relative max-w-xl mx-auto p-6 bg-white rounded">
      <SpinnerComp :loading="loading" />
  
      <!-- Form for creating or editing an exam done -->
      <form @submit.prevent="handleSubmit">
        <div class="mb-6">
          <label for="examType" class="block text-gray-700 font-bold mb-2">Exam Type:</label>
          <select 
            v-model="examType" 
            id="examType" 
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            <option value="">Select Exam Type</option>
            <option v-for="type in examTypes" :key="type.id" :value="type.id">
              {{ type.type }}
            </option>
          </select>
          <p v-if="errors.exam_type_id" class="text-red-500 text-sm mt-1">{{ errors.exam_type_id.join(' ') }}</p>
        </div>
  
        <div class="mb-6">
          <label for="startDate" class="block text-gray-700 font-bold mb-2">Start Date:</label>
          <input 
            type="date" 
            v-model="startDate" 
            id="startDate" 
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
          <p v-if="errors.start_date" class="text-red-500 text-sm mt-1">{{ errors.start_date.join(' ') }}</p>
        </div>
  
        <div class="mb-6">
          <label for="endDate" class="block text-gray-700 font-bold mb-2">End Date:</label>
          <input 
            type="date" 
            v-model="endDate" 
            id="endDate" 
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
          <p v-if="errors.end_date" class="text-red-500 text-sm mt-1">{{ errors.end_date.join(' ') }}</p>
        </div>
  
        <!-- Error message display -->
        <div v-if="errors.message" class="mb-4 text-red-600">
          {{ errors.message }}
        </div>
  
        <!-- Submit or Update/Delete buttons -->
        <div class="flex justify-end space-x-4">
          <button type="submit" class="bg-blue-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-blue-600 transition-colors duration-300">
            {{ id ? 'Update Exam Done' : 'Create Exam Done' }}
          </button>
          <button v-if="id" @click.prevent="handleDelete" class="bg-red-500 hover:bg-red-700 text-white py-3 px-6 rounded-md focus:outline-none transition-colors duration-300">
            Delete Exam Done
          </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from '@/axios';
  import SpinnerComp from '@/components/SpinnerComp.vue';
  
  export default {
    components: {
      SpinnerComp,
    },
    props: {
      id: {
        type: [String, Number],
        default: null
      }
    },
    data() {
      return {
        examType: '',
        startDate: '',
        endDate: '',
        examTypes: [],
        errors: {},
        loading: false,
      };
    },
    computed: {
      isEditMode() {
        return !!this.id;
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(newId) {
          if (newId) {
            this.fetchExamDone(newId);
          }
        }
      }
    },
    created() {
      this.fetchExamTypes();
    },
    methods: {
      fetchExamDone(id) {
        this.loading = true;
        axios.get(`schedule/exams done/${id}`)
          .then(response => {
            const { exam_type_id, start_date, end_date } = response.data;
            this.examType = exam_type_id;
            this.startDate = start_date;
            this.endDate = end_date;
            this.errors = {};
          })
          .catch(error => {
            console.error('Error fetching exam done:', error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      fetchExamTypes() {
        this.loading = true;
        axios.get('schedule/exam types/all')
          .then(response => {
            this.examTypes = response.data;
            this.errors = {};
          })
          .catch(error => {
            console.error('Error fetching exam types:', error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      handleSubmit() {
        this.loading = true;
        const examDoneData = {
          exam_type_id: this.examType,
          start_date: this.startDate,
          end_date: this.endDate,
        };
  
        const request = this.id ? 
          axios.post(`schedule/exams done/${this.id}/update`, examDoneData) : 
          axios.post('schedule/exams done', examDoneData);
  
        request.then(() => {
            const message = this.id ? 'Exam Done Successfully Updated' : 'Exam Done Successfully Created';
            alert(message);
            this.examType = '';
            this.startDate = '';
            this.endDate = '';
            this.errors = {};
            this.$emit('success');
          })
          .catch(error => {
            if (error.response && error.response.data.errors) {
              this.errors = error.response.data.errors;
            } else {
              console.error(error);
              this.errors = { message: 'Something went wrong. Please try again later.' };
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      handleDelete() {
        if (!confirm('Are you sure you want to delete this exam done?')) {
          return;
        }
        this.loading = true;
        axios.post(`schedule/exams done/${this.id}/delete`)
          .then(() => {
            alert('Exam Done Successfully Deleted');
            this.$emit('success');
          })
          .catch(error => {
            console.error('Error deleting exam done:', error);
            this.errors = { message: 'Failed to delete exam done. Please try again later.' };
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
  </script>
  