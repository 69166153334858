<template>
    <div class="relative max-w-xl mx-auto p-6 bg-white rounded">
      <SpinnerComp :loading="loading" />
  
      <!-- Form for creating or editing a house -->
      <form @submit.prevent="handleSubmit">
        <div class="mb-6">
          <label for="houseName" class="block text-gray-700 font-bold mb-2">House Name:</label>
          <input 
            type="text" 
            v-model="houseName" 
            id="houseName" 
            class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
          <p v-if="errors.house" class="text-red-500 text-sm mt-1">{{ errors.house.join(' ') }}</p>
        </div>
  
        <!-- Submit or Update/Delete buttons -->
        <div class="flex justify-end space-x-4">
          <button type="submit" class="bg-blue-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-blue-600 transition-colors duration-300">
            {{ id ? 'Update House' : 'Create House' }}
          </button>
          <button v-if="id" @click.prevent="handleDelete" class="bg-red-500 hover:bg-red-700 text-white py-3 px-6 rounded-md focus:outline-none transition-colors duration-300">
            Delete House
          </button>
        </div>
      </form>
      <!-- Error Message -->
      <div v-if="error" class="text-red-500 mt-4">
        Error: {{ error }}
      </div>
    </div>
  </template>
  
  <script>
  import axios from '@/axios';
  import SpinnerComp from '@/components/SpinnerComp.vue';
  
  export default {
    components: {
      SpinnerComp,
    },
    props: {
      id: {
        type: [String, Number],
        default: null
      }
    },
    data() {
      return {
        houseName: '',
        errors: {},
        loading: false,
        error: ''
      };
    },
    computed: {
      isEditMode() {
        return !!this.id;
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(newId) {
          if (newId) {
            this.fetchHouse(newId);
          }
        }
      }
    },
    methods: {
      fetchHouse(id) {
        this.loading = true;
        axios.get(`schedule/houses/${id}`)
          .then(response => {
            this.houseName = response.data.house;
            this.errors = {};
          })
          .catch(error => {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      handleSubmit() {
        this.loading = true;
        const houseData = {
          house: this.houseName,
        };
  
        const request = this.id ? 
          axios.post(`schedule/houses/${this.id}/update`, houseData) : 
          axios.post('schedule/houses', houseData);
  
        request.then(() => {
            const message = this.id ? 'House Successfully Updated' : 'House Successfully Created';
            alert(message);
            this.houseName = '';
            this.errors = {};
            this.$emit('success');
          })
          .catch(error => {
            if (error.response && error.response.data.errors) {
              this.errors = error.response.data.errors;
            } else {
              const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
              this.error = errorMessage;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      handleDelete() {
        if (!confirm('Are you sure you want to delete this house?')) {
          return;
        }
        this.loading = true;
        axios.post(`schedule/houses/${this.id}/delete`)
          .then(() => {
            alert('House Successfully Deleted');
            this.$emit('success');
          })
          .catch(error => {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
  </script>
  