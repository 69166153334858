<template>
  <div class="flex justify-center mt-6">
    <button
      :disabled="currentPage === 1"
      @click="changePage(currentPage - 1)"
      class="py-2 px-4 mx-1 bg-gray-200 text-gray-700 rounded-lg"
    >
      Previous
    </button>

    <!-- Show the first page button and ellipses if there are more pages before the current range -->
    <button
      v-if="showFirstEllipsis"
      @click="changePage(1)"
      class="py-2 px-4 mx-1 bg-gray-200 text-gray-700 rounded-lg"
    >
      1
    </button>
    <span v-if="showFirstEllipsis" class="py-2 px-4 mx-1 text-gray-700">...</span>

    <!-- Show the range of page buttons around the current page -->
    <button
      v-for="page in visiblePages"
      :key="page"
      :class="['py-2 px-4 mx-1', { 'bg-blue-500 text-white': page === currentPage, 'bg-gray-200 text-gray-700': page !== currentPage }]"
      @click="changePage(page)"
    >
      {{ page }}
    </button>

    <!-- Show the last page button and ellipses if there are more pages after the current range -->
    <span v-if="showLastEllipsis" class="py-2 px-4 mx-1 text-gray-700">...</span>
    <button
      v-if="showLastEllipsis"
      @click="changePage(lastPage)"
      class="py-2 px-4 mx-1 bg-gray-200 text-gray-700 rounded-lg"
    >
      {{ lastPage }}
    </button>

    <button
      :disabled="currentPage === lastPage"
      @click="changePage(currentPage + 1)"
      class="py-2 px-4 mx-1 bg-gray-200 text-gray-700 rounded-lg"
    >
      Next
    </button>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    visiblePages() {
      const range = 3; // Number of pages to show around the current page
      let start = Math.max(this.currentPage - range, 1);
      let end = Math.min(this.currentPage + range, this.lastPage);

      // Adjust start and end if range does not fit within total pages
      if (end - start < range * 2) {
        if (start === 1) {
          end = Math.min(start + range * 2, this.lastPage);
        } else if (end === this.lastPage) {
          start = Math.max(end - range * 2, 1);
        }
      }

      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    showFirstEllipsis() {
      return this.visiblePages[0] > 2;
    },
    showLastEllipsis() {
      return this.visiblePages[this.visiblePages.length - 1] < this.lastPage - 1;
    },
  },
  methods: {
    changePage(page) {
      if (page !== this.currentPage) {
        this.$emit('page-change', page);
      }
    },
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
