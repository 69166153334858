<template>
    <div class="container mx-auto p-8 bg-white shadow-md rounded-lg">
      <h2 class="text-2xl font-bold mb-6 text-center text-gray-800">
        {{ formattedActiveType }}
      </h2>
  
      <router-link to="/dashboard" class="mb-4 flex items-center hover:text-red-400 transition">
          <font-awesome-icon :icon="['fas', 'house']"  class="mr-2"/>
          Home
      </router-link>
      <!-- Search Component -->
      <SearchComponent @search="updateSearch" />
  
      <!-- Toggle Buttons Component -->
      <ToggleButtons
        :types="['roles','role assignments']"
        :activeType="activeType"
        @update-type="updateType"
        class="mb-4"
      />
  
      <!-- New EntityForm Button -->
      <button @click="openForm" class="py-2 px-6 mb-6 bg-blue-500 text-white rounded-lg mt-4">
        New {{ formattedActiveType }}
      </button>
  
      <!-- Record Table Component -->
      <RecordTable
        :members="records"
        :sortField="sortField"
        :sortDirection="sortDirection"
        @sort="updateSort"
        @edit="editRecord"
        @delete="deleteRecord"
      />
  
      <!-- Pagination Component -->
      <PaginationComp
        :currentPage="currentPage"
        :lastPage="lastPage"
        @page-change="updatePage"
      />
  
      <!-- EntityForm Modal -->
      <RoleEntity
        v-if="formOpen"
        :activeType="activeType"
        :id="selectedId"
        @close="formOpen = false"
        @success="handleSuccess"
      />
  
      <!-- Error Message -->
      <div v-if="error" class="text-red-500 mt-4">
        Error: {{ error }}
      </div>
  
      <!-- Loading Spinner-->
      <SpinnerComp :loading="loading" />
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import SearchComponent from '@/components/SearchComponent.vue';
  import ToggleButtons from '@/components/ToggleButtons.vue';
  import RecordTable from '@/components/RecordTable.vue';
  import PaginationComp from '@/components/PaginationComp.vue';
  import SpinnerComp from '@/components/SpinnerComp.vue';
  import RoleEntity from '@/components/forms/RoleEntity.vue';
  import axios from '@/axios';
  
  export default {
    components: {
      SearchComponent,
      ToggleButtons,
      RecordTable,
      RoleEntity,
      PaginationComp,
      SpinnerComp
    },
    mounted() {
      this.fetchRecords();
    },
    data() {
      return {
        formOpen: false,
        selectedId: null
      };
    },
    computed: {
      ...mapGetters('admin', ['records', 'activeType', 'sortField', 'sortDirection', 'currentPage', 'lastPage', 'error', 'loading']),
      formattedActiveType() {
        return this.activeType ? this.activeType.charAt(0).toUpperCase() + this.activeType.slice(1) : '';
      }
    },
    methods: {
      ...mapActions('admin', ['fetchRecords', 'setActiveType',
       'setSearchTerm', 'setSort', 'setPage','setLoading','setError']),
      updateType(type) {
        this.setActiveType(type);
      },
      updateSearch(term) {
        this.setSearchTerm(term);
      },
      updateSort({ field, direction }) {
        this.setSort({ field, direction });
      },
      updatePage(page) {
        this.setPage(page);
      },
      openForm() {
        this.selectedId = null;
        this.formOpen = true;
      },
      editRecord(id) {
        this.selectedId = id;
        this.formOpen = true;
      },
      async deleteRecord(id) {
        if (this.activeType === 'role assignments') {
          this.setLoading(true);
          try {
            await axios.post(`schedule/role-assignments/${id}/delete`);
            await this.fetchRecords();
          }catch(error) {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.setError(errorMessage);
          }finally{
            this.setLoading(false);
          }
        } else if (this.activeType === 'roles') {
          this.setLoading(true);
          try{
            await axios.post(`schedule/roles/${id}/delete`);
            await this.fetchRecords();
          }catch(error) {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.setError(errorMessage);
          }finally {
            this.setLoading(false);
          }
        }
        
      },
      handleSuccess() {
        this.formOpen = false;
        this.fetchRecords();
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 1200px;
  }
  </style>
  