<template>
  <div class="relative max-w-xl mx-auto p-6 bg-white rounded">
    <SpinnerComp :loading="loading" />

    <!-- Form for creating or editing a role -->
    <form @submit.prevent="handleSubmit">
      <div class="mb-6">
        <label for="roleName" class="block text-gray-700 font-bold mb-2">Role Name:</label>
        <input 
          type="text" 
          v-model="roleName" 
          id="roleName" 
          class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
        <p v-if="errors.role" class="text-red-500 text-sm mt-1">{{ errors.role.join(' ') }}</p>
      </div>

      <div class="mb-6">
        <label for="assignableTo" class="block text-gray-700 font-bold mb-2">Assignable To:</label>
        <select v-model="assignableTo" id="assignableTo" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select a category</option>
          <option value="teacher">Teacher</option>
          <option value="student">Student</option>
          <option value="staff">Staff</option>
          <option value="parent">Parent</option>
        </select>
        <p v-if="errors.assignable_to" class="text-red-500 text-sm mt-1">{{ errors.assignable_to.join(' ') }}</p>
      </div>

      <div class="mb-6">
        <label for="jurisdiction" class="block text-gray-700 font-bold mb-2">Jurisdiction:</label>
        <select v-model="jurisdiction" id="jurisdiction" class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600">
          <option disabled value="">Select a jurisdiction</option>
          <option value="class">Class</option>
          <option value="department">Department</option>
          <option value="house">House</option>
          <option value="school">School</option>
        </select>
        <p v-if="errors.jurisdiction" class="text-red-500 text-sm mt-1">{{ errors.jurisdiction.join(' ') }}</p>
      </div>

      <!-- Submit or Update/Delete buttons -->
      <div class="flex justify-end space-x-4">
        <button type="submit" class="bg-blue-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-blue-600 transition-colors duration-300">
          {{ id ? 'Update Role' : 'Create Role' }}
        </button>
        <button v-if="id" @click.prevent="handleDelete" class="bg-red-500 hover:bg-red-700 text-white py-3 px-6 rounded-md focus:outline-none transition-colors duration-300">
          Delete Role
        </button>
      </div>
    </form>
    <!-- Error Message -->
    <div v-if="error" class="text-red-500 mt-4">
      Error: {{ error }}
    </div>
  </div>
</template>

<script>
import axios from '@/axios';
import SpinnerComp from '@/components/SpinnerComp.vue';

export default {
  components: {
    SpinnerComp,
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      roleName: '',
      assignableTo: '',
      jurisdiction: '',
      errors: {},
      loading: false,
      error:''
    };
  },
  computed: {
    isEditMode() {
      return !!this.id;
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.fetchRole(newId);
        }
      }
    }
  },
  methods: {
    fetchRole(id) {
      this.loading = true;
      axios.get(`schedule/roles/${id}`)
        .then(response => {
          this.roleName = response.data.role;
          this.assignableTo = response.data.assignable_to;
          this.jurisdiction = response.data.jurisdiction;
          this.errors = {};
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSubmit() {
      this.loading = true;
      const roleData = {
        role: this.roleName,
        assignable_to: this.assignableTo,
        jurisdiction: this.jurisdiction,
      };

      const request = this.id ? 
        axios.post(`schedule/roles/${this.id}/update`, roleData) : 
        axios.post('schedule/roles', roleData);

      request.then(() => {
          const message = this.id ? 'Role Successfully Updated' : 'Role Successfully Created';
          alert(message);
          this.roleName = '';
          this.assignableTo = '';
          this.jurisdiction = '';
          this.errors = {};
          this.$emit('success');
        })
        .catch(error => {
          if (error.response && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDelete() {
      if (!confirm('Are you sure you want to delete this role?')) {
        return;
      }
      this.loading = true;
      axios.post(`schedule/roles/${this.id}/delete`)
        .then(() => {
          alert('Role Successfully Deleted');
          this.$emit('success');
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

