<template>
  <div class="max-w-screen-xl mx-auto p-4">
    <SpinnerComp :loading="loading" />
    
    <!-- Student Information -->
    <div class="mb-6">
      <h3 class="text-lg font-bold mb-4">Student Information</h3>
      <div class="grid grid-cols-2 gap-4">
        <!-- Existing Fields -->
        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">First Name</label>
          <input
            type="text"
            v-model="studentFormData.first_name"
            class="form-input"
            required
            :class="{ 'w-full': true }"
          />
          <p v-if="getStrippedError('student.first_name')" class="text-red-500 text-sm mt-1">{{ getStrippedError('student.first_name').join(' ') }}</p>
        </div>

        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">Middle Name</label>
          <input
            type="text"
            v-model="studentFormData.middle_name"
            class="form-input"
            :class="{ 'w-full': true }"
          />
          <p v-if="getStrippedError('student.middle_name')" class="text-red-500 text-sm mt-1">{{ getStrippedError('student.middle_name').join(' ') }}</p>
        </div>

        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">Last Name</label>
          <input
            type="text"
            v-model="studentFormData.last_name"
            class="form-input"
            required
            :class="{ 'w-full': true }"
          />
          <p v-if="getStrippedError('student.last_name')" class="text-red-500 text-sm mt-1">{{ getStrippedError('student.last_name').join(' ') }}</p>
        </div>

        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">Date of Birth</label>
          <input
            type="date"
            v-model="studentFormData.date_of_birth"
            class="form-input"
            required
            :class="{ 'w-full': true }"
          />
          <p v-if="getStrippedError('student.date_of_birth')" class="text-red-500 text-sm mt-1">{{ getStrippedError('student.date_of_birth').join(' ') }}</p>
        </div>

        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">Gender</label>
          <select
            v-model="studentFormData.gender"
            class="form-select"
            required
            :class="{ 'w-full': true }"
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          <p v-if="getStrippedError('student.gender')" class="text-red-500 text-sm mt-1">{{ getStrippedError('student.gender').join(' ') }}</p>
        </div>

        <!-- New Fields -->
        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">NEMIS Number</label>
          <input
            type="text"
            v-model="studentFormData.nemis_number"
            class="form-input"
            required
            :class="{ 'w-full': true }"
          />
          <p v-if="getStrippedError('student.nemis_number')" class="text-red-500 text-sm mt-1">{{ getStrippedError('student.nemis_number').join(' ') }}</p>
        </div>

        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">KCPE Marks</label>
          <input
            type="number"
            v-model="studentFormData.kcpe_marks"
            class="form-input"
            required
            :class="{ 'w-full': true }"
          />
          <p v-if="getStrippedError('student.kcpe_marks')" class="text-red-500 text-sm mt-1">{{ getStrippedError('student.kcpe_marks').join(' ') }}</p>
        </div>
      </div>
    </div>
    
    <!-- Parent Information -->
    <div class="mb-6">
      <h3 class="text-lg font-bold mb-4">Guardian Information</h3>
      <div class="grid grid-cols-2 gap-4">
        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">First Name</label>
          <input
            type="text"
            v-model="parentFormData.first_name"
            class="form-input"
            required
            :class="{ 'w-full': true }"
          />
          <p v-if="getStrippedError('parent.first_name')" class="text-red-500 text-sm mt-1">{{ getStrippedError('parent.first_name').join(' ') }}</p>
        </div>

        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">Last Name</label>
          <input
            type="text"
            v-model="parentFormData.last_name"
            class="form-input"
            required
            :class="{ 'w-full': true }"
          />
          <p v-if="getStrippedError('parent.last_name')" class="text-red-500 text-sm mt-1">{{ getStrippedError('parent.last_name').join(' ') }}</p>
        </div>

        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">Email</label>
          <input
            type="email"
            v-model="parentFormData.email"
            class="form-input"
            required
            :class="{ 'w-full': true }"
          />
          <p v-if="getStrippedError('parent.email')" class="text-red-500 text-sm mt-1">{{ getStrippedError('parent.email').join(' ') }}</p>
        </div>

        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">Phone Number</label>
          <input
            type="text"
            v-model="parentFormData.phone_number"
            class="form-input"
            required
            :class="{ 'w-full': true }"
          />
          <p v-if="getStrippedError('parent.phone_number')" class="text-red-500 text-sm mt-1">{{ getStrippedError('parent.phone_number').join(' ') }}</p>
        </div>

        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">Relation</label>
          <select
            v-model="parentFormData.relation"
            class="form-select"
            required
            :class="{ 'w-full': true }"
          >
            <option value="Mother">Mother</option>
            <option value="Father">Father</option>
            <option value="Guardian">Guardian</option>
          </select>
          <p v-if="getStrippedError('parent.relation')" class="text-red-500 text-sm mt-1">{{ getStrippedError('parent.relation').join(' ') }}</p>
        </div>
      </div>
    </div>

    <!-- School Information -->
    <div class="mb-6">
      <h3 class="text-lg font-bold mb-4">School Information</h3>
      <div class="grid grid-cols-2 gap-4">
        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">Class</label>
          <select
            v-model="schoolFormData.class_id"
            class="form-select"
            required
            :class="{ 'w-full': true }"
          >
            <option v-for="option in classOptions" :key="option.id" :value="option.id">{{ option.name }}</option>
          </select>
          <p v-if="getStrippedError('school.class')" class="text-red-500 text-sm mt-1">{{ getStrippedError('school.class').join(' ') }}</p>
        </div>

        <div class="p-2">
          <label class="text-gray-800 font-semibold mb-1 block">House</label>
          <select
            v-model="schoolFormData.house_id"
            class="form-select"
            required
            :class="{ 'w-full': true }"
          >
            <option v-for="option in houseOptions" :key="option.id" :value="option.id">{{ option.house }}</option>
          </select>
          <p v-if="getStrippedError('school.house')" class="text-red-500 text-sm mt-1">{{ getStrippedError('school.house').join(' ') }}</p>
        </div>
      </div>
    </div>

    <!-- Submit Button for Combined Form -->
    <div class="flex justify-end">
      <button
        v-if="!isEditMode"
        type="button"
        @click="submitCombinedForm"
        class="bg-red-500 text-white py-2 px-6 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600 transition-colors duration-300"
      >
        Register Student
      </button>
      <button
        v-if="isEditMode"
        type="button"
        @click="submitCombinedForm"
        class="bg-red-500 text-white py-2 px-6 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600 transition-colors duration-300"
      >
        Edit Info
      </button>
    </div>
    <!-- Error Message -->
    <div v-if="error" class="text-red-500 mt-4">
    Error: {{ error }}
    </div>
  </div>
</template>

<script>
import axios from '../../axios';
import SpinnerComp from '@/components/SpinnerComp.vue';

export default {
  components: { SpinnerComp },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    isEditMode() {
      return !this.id;
    }
  },
  watch: {
    id: {
      immediate: true,
      async handler(newId) {
        if (newId) {
          await this.fetchStudentInfo(newId);
        }
      }
    }
  }, 
  data() {
    return {
      studentFormData: {
        first_name: '',
        last_name: '',
        middle_name : '',
        date_of_birth: '',
        gender: '',
        nemis_number: '',
        kcpe_marks: '',
      }, 
      parentFormData: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        relation: '',
      },
      schoolFormData: {
        class_id: '',
        house_id: '',
      },
      classOptions: [],
      houseOptions: [],
      loading: false,
      errors: {},
      error: ''
    };
  },
  created() {
    this.fetchClassOptions();
    this.fetchHouseOptions();
  },
  methods: {
    fetchClassOptions() {
      axios.get('schedule/classes/enrolled')
        .then(response => {
          this.classOptions = response.data;
        })
        .catch(error => {
          console.error('Error fetching class options:', error);
        });
    },
    fetchHouseOptions() {
      axios.get('schedule/houses/all')
        .then(response => {
          console.log(response.data);
          this.houseOptions = response.data;
        })
        .catch(error => {
          console.error('Error fetching house options:', error);
        });
    },
    getStrippedError(field) {
    return this.errors[field] ? this.errors[field].map(error => {
        const parts = error.split(':');
        return parts.length > 1 ? parts[1].trim() : error.trim();
    }) : null;
    },
    submitCombinedForm() {
      this.loading = true;
      this.errors = {};

      const payload = {
        student: this.studentFormData,
        parent: this.parentFormData,
        school: this.schoolFormData,
      };
      const url = this.isEditMode ? '/users/students' : `/students/${this.id}`;
      console.log(url);
      axios.post(url, payload)
        .then(response => {
          // Handle success
          console.log('Student registered successfully:', response.data);
        })
        .catch(error => {
          if (error.response && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            console.error('Error registering student:', error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async fetchStudentInfo() {
      this.loading = true;
      this.errors = {};
      try {
        const response = await axios.get(`/students/${this.id}`);
        console.log(response.data);
        // Initialize form data properly
        this.studentFormData = {
          first_name: response.data.first_name || '',
          last_name: response.data.last_name || '',
          middle_name: response.data.middle_name || '',
          date_of_birth: response.data.date_of_birth || '',
          gender: response.data.gender || '',
          nemis_number: response.data.nemis_number || '',
          kcpe_marks: response.data.kcpe_marks || ''
        };
        
        this.parentFormData = {
          first_name: response.data.parent?.first_name || '',
          last_name: response.data.parent?.last_name || '',
          email: response.data.parent?.email || '',
          phone_number: response.data.parent?.phone_number || '',
          relation: response.data.parent?.relation || ''
        };

        this.schoolFormData = {
          class_id: response.data.active_enrollment?.class_id || '',
          house_id: response.data.active_house_enrollment?.house_id || ''
        };
        
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors = error.response.data.errors;
        } else {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        }
      } finally {
        this.loading = false;
      }
    },
    async deleteStudentInfo() {
      try {
        this.loading = true;
        const response = await axios.post(`/students/${this.id}/delete`);
        console.log(response.data);
      }catch(error) {
        const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
        this.error = errorMessage;
      }finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped>
.p-2 {
  padding: 0.5rem;
}

.text-gray-800 {
  color: #2d3748;
}

.form-input {
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.form-select {
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
  padding: 0.5rem;
}
</style>
