<template>
  <div class="relative max-w-xl mx-auto p-6 bg-white rounded">
    <SpinnerComp :loading="loading" />
    <!-- Form for creating a new class -->
    <form @submit.prevent="handleSubmit">
      <!-- Form selection -->
      <div class="mb-6">
        <label for="form" class="block text-gray-700 font-bold mb-2">Select Form:</label>
        <select
          v-model="selectedForm"
          id="form"
          class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
        >
          <option value="" disabled>Select Form</option>
          <option v-for="formLevel in formLevels" :key="formLevel" :value="formLevel">Form {{ formLevel }}</option>
        </select>
      </div>

      <!-- Submit button -->
      <div class="flex justify-end">
        <button type="submit" class="bg-blue-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-blue-600 transition-colors duration-300">Create</button>
      </div>
    </form>
    <!-- Error Message -->
    <div v-if="error" class="text-red-500 mt-4">
      Error: {{ error }}
    </div>
  </div>
</template>

<script>
import axios from '@/axios';
import SpinnerComp from '@/components/SpinnerComp.vue';

export default {
  components: {
    SpinnerComp,
  },
  data() {
    return {
      selectedForm: 1, // Default to Form 1
      errors: {},
      loading: false,
      formLevels: [1, 2, 3, 4], // Example form levels, adjust as needed
      error: ''
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;

      // Prepare data for the request
      let requestData = {
        selectedForm: this.selectedForm,
      };

      // Make API call
      axios.post('schedule/classes', requestData)
        .then(() => {
          alert(`Form ${this.selectedForm} class successfully created`);
          // Reset form state after submission
          this.selectedForm = '1'; // Reset to default
          this.errors = {}; // Clear errors
        })
        .catch(error => {
          if (error.response && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
            this.error = errorMessage;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
