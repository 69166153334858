<template>
    <div>
      <NavBar />
      <main class="container mx-auto px-4 py-8">
        <div class="max-w-7xl mx-auto">
          <slot />
        </div>
      </main>
      <FooterComp />
    </div>
  </template>
  
  <script>
  import NavBar from './NavBar.vue';
  import FooterComp from './FooterComp.vue';
  
  export default {
    name: 'LayoutComp',
    components: {
      NavBar,
      FooterComp,
    }
  };
  </script>
  
