<template>
  <div class="relative max-w-xl mx-auto p-6 bg-white rounded">
    <SpinnerComp :loading="loading" />
    <form @submit.prevent="handleSubmit">
      <div class="mb-6">
        <label for="startDate" class="block text-gray-700 font-bold mb-2">Start Date:</label>
        <input 
          type="date" 
          v-model="start_date" 
          id="startDate" 
          class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
        <p v-if="errors.start_date" class="text-red-500 text-sm mt-1">{{ errors.start_date.join(' ') }}</p>
      </div>
      <div class="mb-6">
        <label for="endDate" class="block text-gray-700 font-bold mb-2">End Date:</label>
        <input 
          type="date" 
          v-model="end_date" 
          id="endDate" 
          class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
        <p v-if="errors.end_date" class="text-red-500 text-sm mt-1">{{ errors.end_date.join(' ') }}</p>
      </div>
      <div class="flex justify-end space-x-4">
        <button type="submit" class="bg-blue-500 hover:bg-black text-white py-3 px-6 rounded-md focus:outline-none focus:bg-blue-600 transition-colors duration-300">
          {{ id ? 'Update Term' : 'Create Term' }}
        </button>
        <button v-if="id" @click.prevent="handleDelete" class="bg-red-500 hover:bg-red-700 text-white py-3 px-6 rounded-md focus:outline-none transition-colors duration-300">
          Delete Term
        </button>
      </div>
    </form>
    <!-- Error Message -->
    <div v-if="error" class="text-red-500 mt-4">
      Error: {{ error }}
    </div>
  </div>
</template>

<script>
import axios from '@/axios';
import SpinnerComp from '@/components/SpinnerComp.vue';

export default {
  components: {
    SpinnerComp,
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      start_date: '',
      end_date: '',
      errors: {},
      loading: false,
      error: ''
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.fetchData(newId);
        }
      }
    }
  },
  methods: {
    fetchData(id) {
      this.loading = true;
      axios.get(`/schedule/terms/${id}`)
        .then(response => {
          this.start_date = response.data.start_date;
          this.end_date = response.data.end_date;
          this.errors = {};
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSubmit() {
      this.loading = true;
      const termData = {
        start_date: this.start_date,
        end_date: this.end_date,
      };

      if (this.id) {
        axios.post(`/schedule/terms/${this.id}/update`, termData)
          .then(() => {
            alert('Term Successfully Updated');
            this.$emit('success');
          })
          .catch(error => {
            if (error.response && error.response.data.errors) {
              this.errors = error.response.data.errors;
            } else {
              const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
              this.error = errorMessage;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        console.log('Sending the request');
        axios.post('/schedule/terms', termData)
          .then(() => {
            alert('Term Successfully Created');
            this.start_date = '';
            this.end_date = '';
            this.errors = {};
            this.$emit('success');
          })
          .catch(error => {
            console.log('Error has occurred');
            console.log(error);
            if (error.response && error.response.data.errors) {
              this.errors = error.response.data.errors;
            } else {
              const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
              this.error= errorMessage;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleDelete() {
      this.loading = true;
      axios.post(`/schedule/terms/${this.id}/delete`)
        .then(() => {
          alert('Term Successfully Deleted');
          this.$emit('success');
        })
        .catch(error => {
          const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message;
          this.error = errorMessage;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
