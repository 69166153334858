<template>
  <div id="app" class="flex flex-col min-h-screen">
    
      <router-view />
    
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>


