<template>
  <div class="w-full max-w-full relative">
    <input
      type="text"
      v-model="query"
      @input="filterItems"
      @keydown.down.prevent="navigate(1)"
      @keydown.up.prevent="navigate(-1)"
      @keydown.enter.prevent="selectActiveItem"
      @keydown.tab.prevent="selectActiveItem"
      @blur="handleBlur"
      :placeholder="`Search ${label}...`"
      class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
    />
    <ul
      v-if="filteredItems.length"
      class="absolute z-10 w-full border border-gray-300 rounded-md mt-1 bg-white"
    >
      <li
        v-for="(item, index) in filteredItems"
        :key="item.id"
        :class="{
          'bg-blue-100': index === activeIndex,
          'p-2': true,
          'border-b': true,
          'last:border-b-0': true,
          'border-gray-200': true,
          'cursor-pointer': true
        }"
        @mousedown.prevent="selectItem(item)"
        @mouseover="setActive(index)"
      >
        {{ getItemDisplayText(item) }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'ItemFilter',
  props: {
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: 'Items'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      query: this.value,
      filteredItems: [],
      activeIndex: -1
    };
  },
  watch: {
    value(newValue) {
      this.query = newValue;
    },
    query(newValue) {
      this.$emit('input', newValue);
    }
  },
  methods: {
    filterItems() {
      const searchQuery = this.query.toLowerCase();
      this.filteredItems = this.items
        .filter(item => 
          this.getItemDisplayText(item).toLowerCase().includes(searchQuery)
        )
        .slice(0, 5);
      this.activeIndex = -1;
    },
    getItemDisplayText(item) {
      const keys = Object.keys(item).filter(key => key !== 'id');
      return keys.map(key => item[key]).join(' ');
    },
    navigate(direction) {
      if (this.filteredItems.length === 0) return;
      this.activeIndex = (this.activeIndex + direction + this.filteredItems.length) % this.filteredItems.length;
    },
    setActive(index) {
      this.activeIndex = index;
    },
    selectItem(item) {
      this.query = this.getItemDisplayText(item);
      this.$emit('input', this.query);
      this.$emit('item-selected', item.id);
      this.filteredItems = [];
      this.activeIndex = -1;
    },
    selectActiveItem() {
      if (this.activeIndex !== -1) {
        this.selectItem(this.filteredItems[this.activeIndex]);
      }
    },
    handleBlur() {
      setTimeout(() => {
        this.clearFilteredItems();
      }, 200);
    },
    clearFilteredItems() {
      this.filteredItems = [];
    }
  }
};
</script>
