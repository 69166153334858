<template>
  <nav class="flex justify-between items-center mb-4 bg-white shadow-md py-4 px-6 rounded-lg">
    <SpinnerComp :loading="loading" />
    <a href="/" class="flex items-center space-x-2">
      <img :src="logoUrl" alt="Logo" class="w-24 h-16">
    </a>
    <ul class="flex space-x-6 text-lg">
      <!-- Conditionally render based on authentication and route -->
      <template v-if="isAuthenticated && isDashboardPage">
        <li @click="openProfileUpdate" class="group cursor-pointer">
          <span class="font-medium text-base group-hover:text-sm 
          group-hover:text-blue-500 group-hover:underline
          transition-all duration-300 ease-in-out">
            Welcome {{ userName }}
          </span>
        </li>
        <li>
          <form @submit.prevent="logout" class="inline">
            <button type="submit" class="hover:text-red-500">
              <i class="fa-solid fa-door-closed"></i>
              Logout
            </button>
          </form>
        </li>
      </template>
      <template v-else>
        <li v-if="!isDashboardPage">
          <a href="/register" class="hover:text-red-500">
            <i class="fa-solid fa-user-plus"></i>
            Register
          </a>
        </li>
        <li v-if="isDashboardPage">
          <a href="/login" class="hover:text-red-500">
            <i class="fa-solid fa-arrow-right-to-bracket"></i>
            Login
          </a>
        </li>
      </template>
    </ul>
    <ProfileUpdate v-if="showProfile" @close="showProfile=false" />
  </nav>
</template>

<script>
import axiosInstance from '@/axios'; // Your axios instance with the '/api' prefix
import axios from 'axios'; // Plain axios for the CSRF token request
import SpinnerComp from './SpinnerComp.vue';
import ProfileUpdate from '@/components/forms/ProfileUpdate.vue';
import Logo from '@/assets/logo.png';

export default {
  data() {
    return {
      loading: false,
      logoUrl: Logo,
      showProfile: false
    };
  },
  components: { SpinnerComp, ProfileUpdate },
  computed: {
    isAuthenticated() {
      return !!localStorage.getItem('token');
    },
    userName() {
      return localStorage.getItem('name') || 'User';
    },
    isDashboardPage() {
      return this.$route.name === 'Dashboard';
    }
  },
  methods: {
    logout() {
      this.loading = true;
      axios.get(process.env.VUE_APP_CSRF_URL, {
        withCredentials: true 
      })
      .then(() => {
        return axiosInstance.post('/users/logout');
      })
      .then(() => {
        this.loading = false;
        localStorage.removeItem('token');
        localStorage.removeItem('user_type');
        localStorage.removeItem('name');
        this.$router.push('/login');
      })
      .catch(error => {
        console.error('Logout failed:', error);
        this.loading = false;
      });
    },
    openProfileUpdate() {
      this.showProfile = true;
    }
  }
};
</script>
