<template>
    <ModalLayout class="z-20">
        <div class="p-10 max-w-lg mx-auto mb-34 bg-white rounded-lg shadow-lg relative">
            <h3 class="text-xl font-bold mb-4 text-center">Profile Details</h3>
            <!-- Close Icon -->
            <button @click="closeModal" class="absolute top-0 right-0 mt-2 mr-2 text-gray-600 hover:text-red-600 focus:outline-none text-xl">
                <font-awesome-icon icon="times" />
            </button>
            <!-- Spinner Component -->
            <SpinnerComp :loading="loading" />
            <form @submit.prevent="updateProfile" @keydown.enter.prevent>
                <div class="mb-6">
                <label for="email" class="block text-gray-700 font-bold mb-2">Email</label>
                <input
                    type="email"
                    id="email"
                    class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                    v-model="form.email"
                    readonly
                />
                </div>

                <div class="mb-6">
                <label for="first_name" class="block text-gray-700 font-bold mb-2">First Name</label>
                <input
                    type="text"
                    id="first_name"
                    class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                    v-model="form.first_name"
                />
                <p v-if="errors.first_name" class="text-red-500 text-sm mt-1">{{ errors.first_name.join(' ') }}</p>
                </div>

                <div class="mb-6">
                <label for="last_name" class="block text-gray-700 font-bold mb-2">Last Name</label>
                <input
                    type="text"
                    id="last_name"
                    class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                    v-model="form.last_name"
                />
                <p v-if="errors.last_name" class="text-red-500 text-sm mt-1">{{ errors.last_name.join(' ') }}</p>
                </div>

                <div class="mb-6">
                <label for="phone_number" class="block text-gray-700 font-bold mb-2">Phone Number</label>
                <input
                    type="text"
                    id="phone_number"
                    class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                    v-model="form.phone_number"
                />
                <p v-if="errors.phone_number" class="text-red-500 text-sm mt-1">{{ errors.phone_number.join(' ') }}</p>
                </div>

                <div class="mb-6">
                    <label for="department_id" class="block text-gray-700 font-bold mb-2">Department</label>
                    <select
                    id="department_id"
                    class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                    v-model="form.department_id"
                    >
                    <option v-for="department in departments" :key="department.id" :value="department.id">
                        {{ department.name }}
                    </option>
                    </select>
                    <p v-if="errors.department_id" class="text-red-500 text-sm mt-1">{{ errors.department_id.join(' ') }}</p>
                </div>

                <div class="mb-6">
                    <label for="employment_type_id" class="block text-gray-700 font-bold mb-2">Employment Type</label>
                    <select
                    id="employment_type_id"
                    class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                    v-model="form.employment_type_id"
                    >
                    <option v-for="employment_type in employment_types" :key="employment_type.id" :value="employment_type.id">
                        {{ employment_type.name }}
                    </option>
                    </select>
                    <p v-if="errors.employment_type_id" class="text-red-500 text-sm mt-1">{{ errors.employment_type_id.join(' ') }}</p>
                </div>

                <div class="mb-6">
                    <label for="tsc_number" class="block text-gray-700 font-bold mb-2">TSC Number</label>
                    <input
                    type="text"
                    id="tsc_number"
                    class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                    v-model="form.tsc_number"
                    />
                    <p v-if="errors.tsc_number" class="text-red-500 text-sm mt-1">{{ errors.tsc_number.join(' ') }}</p>
                </div>

                <div class="mb-6">
                    <label for="gender" class="block text-gray-700 font-bold mb-2">Gender</label>
                    <select
                    id="gender"
                    class="border border-gray-300 rounded-md w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-600"
                    v-model="form.gender"
                    >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    </select>
                    <p v-if="errors.gender" class="text-red-500 text-sm mt-1">{{ errors.gender.join(' ') }}</p>
                </div>

                <div class="mb-6">
                <button type="submit" class="bg-laravel text-white py-3 px-6 rounded-md hover:bg-black 
                transition-colors duration-300 w-full">Update</button>
                </div>
            </form>
            <div class="errors"><p v-for="(error,index) in errors" :key="index">{{ error }}</p></div>
            <p class="text-green-500" v-if="success">Profile updated successfully!</p>
        </div>
    </ModalLayout>
</template>

<script>
import axios from '../../axios';
import ModalLayout from '@/components/ModalLayout.vue';
import SpinnerComp from '@/components/SpinnerComp.vue'; // Import the Spinner component

export default {
  name: 'RegisterPage',
  components: { ModalLayout, SpinnerComp }, // Include the SpinnerComp in the components object
  data() {
    return {
      token: '',
      position: '',
      form: {
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        department_id: '',
        employment_type_id: '',
        tsc_number: '',
        gender: '', // Add gender field
      },
      departments: [],
      employment_types: [],
      errors: {},
      loading: false,
      success: false,
    };
  },
  async mounted() {
    await this.fetchEmploymentTypes();
    await this.fetchDepartments();
    await this.fetchProfile();
  },
  methods: {
    async fetchEmploymentTypes() {
        this.loading = true;
        try {
            const response = await axios.get('/employment-types');
            this.employment_types = response.data;
        }catch(error) {
            console.log('Error Fetching Employment Types',error);
        }finally{
            this.loading = false;
        }
    },
    async fetchDepartments() {
        this.loading = true;
        try {
            const response = await axios.get('/departments');
            this.departments = response.data;
        }catch(error) {
            console.log('Error Fetching Departments',error);
        }finally{
            this.loading = false;
        }
    },
    async fetchProfile() {
        this.loading = true;
        try {
            const response = await axios.get('/users/profile');
            this.form = response.data;
        }catch(error) {
            console.log('Error Fetching Profile',error);
        }finally{
            this.loading = false;
        }
    },
    async updateProfile() {
        this.loading = true;
        try {
            await axios.post('/users/profile/update',this.form);
            this.success = true;
        }catch(error) {
            this.success = false;
            console.log('Error Updating Profile',error);
        }finally{
            this.loading = false;
        }
    },
    closeModal() {
        this.$emit('close');
    },
  }
};
</script>