<template>
  <div class="relative border-2 border-gray-100 m-4 rounded-lg">
    <div class="absolute top-4 left-3">
      <i class="fa fa-search text-gray-400 z-20 hover:text-gray-500"></i>
    </div>
    <input
      type="text"
      name="search"
      v-model="searchTerm"
      @input="handleInput"
      class="h-14 w-full pl-10 pr-20 rounded-lg z-0 focus:shadow focus:outline-none"
      placeholder="Search..."
    />
    <div class="absolute top-2 right-2">
      <button
        type="button"
        @click="handleSearch"
        class="h-10 w-20 text-white rounded-lg bg-red-500 hover:bg-red-600"
      >
        Search
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: '',
    };
  },
  methods: {
    handleInput(event) {
      // Update searchTerm as user types
      this.searchTerm = event.target.value;
    },
    handleSearch() {
      // Trigger search event or perform search operation here
      this.$emit('search', this.searchTerm);
    },
  },
};
</script>


